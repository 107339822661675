.read-only .tooth + .popup {
  display: none; }

.read-only .tooth.active:hover + .popup {
  display: block; }

.read-only .add-ipr-button > .popup {
  display: none; }

.read-only .add-ipr-button > .popup.active {
  display: block; }

.read-only .add-ipr-button:hover > .popup {
  display: block; }

.tooth-popup {
  border: 1px solid #c8c8ca;
  border-radius: 8px;
  background-color: white;
  padding: 16px; }
  .tooth-popup.top {
    display: block;
    transform: translate(-50%, -100%);
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);
    left: 50%;
    top: -16px; }
    .tooth-popup.top::after {
      content: " ";
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-top: 15px solid white;
      position: absolute;
      bottom: -15px;
      left: calc(50% - 14px); }
  .tooth-popup.bottom {
    display: block;
    transform: translate(-50%, 100%);
    box-shadow: 0px -8px 12px rgba(0, 0, 0, 0.25);
    left: 50%;
    bottom: -16px; }
    .tooth-popup.bottom::after {
      content: " ";
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-bottom: 15px solid white;
      position: absolute;
      top: -15px;
      left: calc(50% - 14px); }

.tooth-popup-elastic {
  border: 1px solid #c8c8ca;
  border-radius: 8px;
  background-color: white;
  padding: 16px; }
  .tooth-popup-elastic.right {
    display: block;
    transform: translate(-100%, -50%);
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25); }
    .tooth-popup-elastic.right::after {
      content: " ";
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-top: 15px solid white;
      position: absolute;
      left: calc(50% - 14px); }
  .tooth-popup-elastic.left {
    display: block;
    transform: translate(15%, 50%);
    box-shadow: 0px -8px 12px rgba(0, 0, 0, 0.25);
    bottom: -16px; }
    .tooth-popup-elastic.left::after {
      content: " ";
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-bottom: 15px solid white;
      position: absolute;
      left: calc(50% - 14px); }

div.read-only .add-ipr-button:has(.add-ipr-icon:hover, .add-ipr-stem:hover) {
  cursor: default; }
  div.read-only .add-ipr-button:has(.add-ipr-icon:hover, .add-ipr-stem:hover) circle {
    fill: #898a8d !important; }

.add-ipr-button:has(.add-ipr-icon:hover, .add-ipr-stem > path:hover) {
  cursor: pointer; }
  .add-ipr-button:has(.add-ipr-icon:hover, .add-ipr-stem > path:hover) circle, .add-ipr-button:has(.add-ipr-icon:hover, .add-ipr-stem > path:hover) .add-ipr-stem path {
    fill: #3084f0; }

.add-ipr-button .number-circle ~ .add-ipr-stem path {
  fill: #3084f0; }

.add-ipr-button:has(.active) .add-ipr-stem path, .add-ipr-button:has(.active) .add-ipr-icon circle {
  fill: #3084f0 !important; }

.add-ipr-button svg.active circle {
  fill: #3084f0; }

.add-ipr-button svg.filled circle {
  fill: #8db7ee; }

.add-ipr-button .number-circle {
  width: 28px;
  height: 28px;
  left: -4px;
  top: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 50%;
  background-color: #3084f0;
  color: white;
  font-weight: 600; }
  @media (max-width: 1024px) {
    .add-ipr-button .number-circle {
      font-size: 10px;
      width: 26px;
      height: 26px;
      left: -3px;
      top: -3px; } }
  @media (max-width: 768px) {
    .add-ipr-button .number-circle {
      font-size: 7px;
      width: 14px;
      height: 14px;
      left: 0;
      top: 0; } }

.add-ipr-button .triangle-pk {
  width: 0;
  height: 0;
  margin-left: calc(50% - 8px);
  margin-right: calc(50% - 8px);
  bottom: 0;
  position: absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #e4e4e4; }
  .add-ipr-button .triangle-pk.rotate {
    bottom: auto;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 16px solid #e4e4e4;
    border-bottom: 0; }

.tooth-popup-applied-treatment {
  border: 1px solid #c8c8ca;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);
  padding: 0; }
  .tooth-popup-applied-treatment.top {
    transform: translate(-50%, -100%);
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);
    left: 50%;
    top: -16px; }
    .tooth-popup-applied-treatment.top::after {
      content: " ";
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-top: 15px solid white;
      position: absolute;
      bottom: -15px;
      left: calc(50% - 14px); }
  .tooth-popup-applied-treatment.bottom {
    transform: translate(-50%, -100%);
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);
    left: 50%;
    top: -16px; }
    .tooth-popup-applied-treatment.bottom::after {
      content: " ";
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-top: 15px solid white;
      position: absolute;
      bottom: -15px;
      left: calc(50% - 14px); }

#teeth-grid {
  text-align: center;
  grid-template-columns: repeat(7, 1fr minmax(auto, 7px)) 1fr minmax(auto, 10px) 1fr repeat(7, minmax(auto, 7px) 1fr);
  grid-template-rows: 1fr 16px 1fr;
  grid-template-areas: "t0 i0 t1 i1 t2 i2 t3 i3 t4 i4 t5 i5 t6 i6 t7 i7 t8 i8 t9 i9 t10 i10 t11 i11 t12 i12 t13 i13 t14 i14 t15" "right . . . . . . . . . . . . . . . . . . . . . . . . . . . . . left" "t16 i16 t17 i17 t18 i18 t19 i19 t20 i20 t21 i21 t22 i22 t23 i23 t24 i24 t25 i25 t26 i26 t27 i27 t28 i28 t29 i29 t30 i30 t31"; }
  #teeth-grid .quadrant-horizontal {
    grid-row-start: 2;
    grid-column: 2 / span 29; }
  #teeth-grid .quadrant-vertical {
    grid-row: 1 / span 3;
    grid-column-start: 16; }
  #teeth-grid.primary-teeth {
    grid-template-rows: 1fr 1fr 16px 1fr 1fr;
    grid-template-areas: "t0 i0 t1 i1 t2 i2 t3 i3 t4 i4 t5 i5 t6 i6 t7 i7 t8 i8 t9 i9 t10 i10 t11 i11 t12 i12 t13 i13 t14 i14 t15" ". . . . . . p3 i3 p4 i4 p5 i5 p6 i6 p7 i7 p8 i8 p9 i9 p10 i10 p11 i11 p12 . . . . . ." "right . . . . . . . . . . . . . . . . . . . . . . . . . . . . . left" ". . . . . . p19 i19 p20 i20 p21 i21 p22 i22 p23 i23 p24 i24 p25 i25 p26 i26 p27 i27 p28 . . . . . ." "t16 i16 t17 i17 t18 i18 t19 i19 t20 i20 t21 i21 t22 i22 t23 i23 t24 i24 t25 i25 t26 i26 t27 i27 t28 i28 t29 i29 t30 i30 t31"; }
    #teeth-grid.primary-teeth .quadrant-horizontal {
      grid-row-start: 3;
      grid-column: 2 / span 29; }
    #teeth-grid.primary-teeth .quadrant-vertical {
      grid-row: 1 / span 5;
      grid-column-start: 16; }
    #teeth-grid.primary-teeth.only-primary-teeth {
      grid-template-rows: 1fr 16px 1fr;
      grid-template-areas: ". . . . . . p3 i3 p4 i4 p5 i5 p6 i6 p7 i7 p8 i8 p9 i9 p10 i10 p11 i11 p12 . . . . . ." "right . . . . . . . . . . . . . . . . . . . . . . . . . . . . . left" ". . . . . . p19 i19 p20 i20 p21 i21 p22 i22 p23 i23 p24 i24 p25 i25 p26 i26 p27 i27 p28 . . . . . ."; }
      #teeth-grid.primary-teeth.only-primary-teeth .quadrant-horizontal {
        grid-row-start: 2;
        grid-column: 2 / span 29; }
    #teeth-grid.primary-teeth.read-only {
      grid-template-areas: "t0 i0 t1 i1 t2 i2 t3 i3 t4 i4 t5 i5 t6 i6 t7 i7 t8 i8 t9 i9 t10 i10 t11 i11 t12 i12 t13 i13 t14 i14 t15" ". . . . . . p3 . p4 . p5 . p6 . p7 . p8 . p9 . p10 . p11 . p12 . . . . . ." "right . . . . . . . . . . . . . . . . . . . . . . . . . . . . . left" ". . . . . . p19 . p20 . p21 . p22 . p23 . p24 . p25 . p26 . p27 . p28 . . . . . ." "t16 i16 t17 i17 t18 i18 t19 i19 t20 i20 t21 i21 t22 i22 t23 i23 t24 i24 t25 i25 t26 i26 t27 i27 t28 i28 t29 i29 t30 i30 t31"; }
  #teeth-grid div.read-only:hover {
    cursor: default; }
  #teeth-grid div.read-only div.numbering:hover {
    cursor: default; }
  #teeth-grid div.read-only div.numbering.numbering-active:hover {
    cursor: pointer; }
  #teeth-grid div.read-only svg.tooth {
    z-index: 10; }
    #teeth-grid div.read-only svg.tooth:hover {
      cursor: default; }
    #teeth-grid div.read-only svg.tooth.active:hover {
      cursor: pointer; }
    #teeth-grid div.read-only svg.tooth:not(.active) > g:first-child path {
      fill: #afafaf;
      stroke: none; }
      #teeth-grid div.read-only svg.tooth:not(.active) > g:first-child path:hover {
        fill: #e4e4e4; }
    #teeth-grid div.read-only svg.tooth.ipr-restrict.active:hover > g:first-child path {
      fill: #e4e4e4; }
  #teeth-grid div.disabled:hover {
    cursor: not-allowed; }
  #teeth-grid div.disabled div.numbering {
    color: #c8c8ca; }
    #teeth-grid div.disabled div.numbering:hover {
      cursor: not-allowed; }
  #teeth-grid div.disabled svg.tooth:hover {
    cursor: not-allowed; }
  #teeth-grid div.disabled svg.tooth > g:first-child path {
    fill: #f5f5f5;
    stroke: none; }
    #teeth-grid div.disabled svg.tooth > g:first-child path:hover {
      fill: #f5f5f5; }
  #teeth-grid div.numbering {
    color: initial; }
    #teeth-grid div.numbering:hover {
      cursor: pointer; }
    #teeth-grid div.numbering.arch-expansion {
      color: #c8c8ca; }
      #teeth-grid div.numbering.arch-expansion:hover {
        cursor: not-allowed; }
    #teeth-grid div.numbering.numbering-active {
      color: #3084f0; }
      #teeth-grid div.numbering.numbering-active.extraction {
        color: #d90429; }
      #teeth-grid div.numbering.numbering-active.ipr-restrict, #teeth-grid div.numbering.numbering-active.attachment-restrict, #teeth-grid div.numbering.numbering-active.movement-restrict {
        color: #c8c8ca; }
      #teeth-grid div.numbering.numbering-active.bite-ramps:hover, #teeth-grid div.numbering.numbering-active.arch-expansion:hover {
        cursor: default; }
  #teeth-grid svg.tooth:hover {
    cursor: pointer; }
  #teeth-grid svg.tooth > g:not(g:first-child) {
    display: none; }
  #teeth-grid svg.tooth > g:first-child path {
    fill: #d9d9d9;
    stroke: none; }
    #teeth-grid svg.tooth > g:first-child path:hover {
      fill: #b5b5b5; }
  #teeth-grid svg.tooth.ipr-restrict > g:first-child path {
    fill: #d9d9d9;
    stroke: none; }
    #teeth-grid svg.tooth.ipr-restrict > g:first-child path:hover {
      fill: #b5b5b5; }
  #teeth-grid svg.tooth:not(.select-normal).bite-ramps:hover, #teeth-grid svg.tooth.arch-expansion:hover {
    cursor: not-allowed; }
  #teeth-grid svg.tooth:not(.select-normal).bite-ramps > g:first-child path, #teeth-grid svg.tooth.arch-expansion > g:first-child path {
    fill: #f5f5f5;
    stroke: none; }
    #teeth-grid svg.tooth:not(.select-normal).bite-ramps > g:first-child path:hover, #teeth-grid svg.tooth.arch-expansion > g:first-child path:hover {
      fill: #f5f5f5; }
  #teeth-grid svg.tooth.active > g:first-child path:hover {
    fill: none; }
  #teeth-grid svg.tooth.active.ipr-restrict > g.ipr-restrict {
    display: block; }
  #teeth-grid svg.tooth.active.ipr-restrict > g:first-child path {
    fill: #f5f5f5;
    stroke: none; }
  #teeth-grid svg.tooth.active.attachment-restrict > g.attachment-restrict {
    display: block; }
  #teeth-grid svg.tooth.active.attachment-restrict > g:first-child path {
    fill: #f5f5f5;
    stroke: none; }
  #teeth-grid svg.tooth.active.attachment-allow > g.attachment-allow {
    display: block; }
  #teeth-grid svg.tooth.active.attachment-allow > g:first-child path {
    fill: rgba(48, 132, 240, 0.2);
    stroke: none; }
  #teeth-grid svg.tooth.active.elastic-cut > g.elastic-cut {
    display: block; }
  #teeth-grid svg.tooth.active.elastic-cut > g:first-child path {
    fill: rgba(48, 132, 240, 0.2);
    stroke: none; }
  #teeth-grid svg.tooth.active.elastic-cut-left > g.elastic-cut-left {
    display: block; }
  #teeth-grid svg.tooth.active.elastic-cut-left > g:first-child path {
    fill: rgba(48, 132, 240, 0.2);
    stroke: none; }
  #teeth-grid svg.tooth.active.elastic-cut-center > g.elastic-cut-center {
    display: block; }
  #teeth-grid svg.tooth.active.elastic-cut-center > g:first-child path {
    fill: rgba(48, 132, 240, 0.2);
    stroke: none; }
  #teeth-grid svg.tooth.active.elastic-cut-right > g.elastic-cut-right {
    display: block; }
  #teeth-grid svg.tooth.active.elastic-cut-right > g:first-child path {
    fill: rgba(48, 132, 240, 0.2);
    stroke: none; }
  #teeth-grid svg.tooth.active.elastic-button > g.elastic-button {
    display: block; }
  #teeth-grid svg.tooth.active.elastic-button > g:first-child path {
    fill: rgba(48, 132, 240, 0.2);
    stroke: none; }
  #teeth-grid svg.tooth.active.movement-restrict > g.movement-restrict {
    display: block; }
  #teeth-grid svg.tooth.active.movement-restrict > g:first-child path {
    fill: #f5f5f5;
    stroke: none; }
  #teeth-grid svg.tooth.active.over-correction > g.over-correction {
    display: block; }
  #teeth-grid svg.tooth.active.over-correction > g:first-child path {
    fill: rgba(48, 132, 240, 0.2);
    stroke: none; }
  #teeth-grid svg.tooth.active.primary-teeth > g.primary-teeth {
    display: block; }
  #teeth-grid svg.tooth.active.primary-teeth > g:first-child path {
    fill: rgba(48, 132, 240, 0.2);
    stroke: #3084f0;
    stroke-width: 2px; }
  #teeth-grid svg.tooth.active.pontics > g.pontics {
    display: block; }
  #teeth-grid svg.tooth.active.pontics > g:first-child path {
    fill: rgba(48, 132, 240, 0.2);
    stroke: #3084f0;
    stroke-dasharray: 10, 10;
    stroke-width: 6px; }
  #teeth-grid svg.tooth.active.bite-ramps:hover {
    cursor: default; }
  #teeth-grid svg.tooth.active.bite-ramps > g.bite-ramps {
    display: block; }
  #teeth-grid svg.tooth.active.bite-ramps > g:first-child path {
    fill: #d6e6fc;
    stroke: none; }
  #teeth-grid svg.tooth.active.extraction > g.extraction {
    display: block; }
  #teeth-grid svg.tooth.active.extraction > g:first-child path {
    fill: rgba(252, 74, 105, 0.1);
    stroke: none; }
  #teeth-grid svg.tooth.active.torque-enhancers > g.torque-enhancers {
    display: block; }
  #teeth-grid svg.tooth.active.torque-enhancers > g:first-child path {
    fill: #d6e6fc;
    stroke: none; }
  #teeth-grid svg.tooth.active.missing-tooth > g.missing-tooth {
    display: block; }
  #teeth-grid svg.tooth.active.missing-tooth > g:first-child path {
    fill: none;
    stroke: #d90429;
    stroke-width: 2px;
    stroke-dasharray: 5; }
  #teeth-grid svg.tooth.active.arch-expansion:hover {
    cursor: default; }
  #teeth-grid svg.tooth.active.arch-expansion > g:first-child path {
    fill: #d6e6fc;
    stroke: #3084f0;
    stroke-width: 2px; }
  #teeth-grid svg.tooth.active.eruption-space:hover {
    cursor: default; }
  #teeth-grid svg.tooth.active.eruption-space > g:first-child path {
    display: block;
    fill: #d6e6fc; }
  #teeth-grid svg.tooth.active.eruption-space > g.eruption-space path {
    display: block !important;
    stroke-width: 2px; }
  #teeth-grid svg.tooth.active.eruption-space > g.eruption-space {
    display: block; }

svg.tooth.upper-left.elastic-cut {
  transform: rotateY(180deg);
  transform-origin: center; }

svg.tooth.lower-right.elastic-cut {
  transform: rotateY(180deg);
  transform-origin: center; }

#teeth-grid.pdf {
  max-width: 600px !important;
  margin: auto; }
  #teeth-grid.pdf .arrow-container {
    margin: 6px 0; }
  #teeth-grid.pdf svg.tooth.active:not(.ipr-restrict):not(.missing-tooth):not(.extraction) > g:first-child path {
    fill: #87baff; }
  #teeth-grid.pdf svg.tooth.active:not(.ipr-restrict):not(.missing-tooth):not(.extraction) > g:not(:first-child) path,
  #teeth-grid.pdf svg.tooth.active:not(.ipr-restrict):not(.missing-tooth):not(.extraction) > g:not(:first-child) line {
    fill: #004eb2;
    stroke: #004eb2; }
  #teeth-grid.pdf .tooth-selector:has(+ .enable-ipr-selector .arrow-num)
+ .enable-ipr-selector
+ .tooth-selector svg.tooth:not(.ipr-restrict.active):not(.missing-tooth.active) g:first-child path {
    fill: #87baff; }
  #teeth-grid.pdf .tooth-selector:has(+ .enable-ipr-selector .arrow-num)
+ .enable-ipr-selector
+ .tooth-selector svg.tooth:not(.ipr-restrict.active):not(.missing-tooth.active) g:not(:first-child) path,
  #teeth-grid.pdf .tooth-selector:has(+ .enable-ipr-selector .arrow-num)
+ .enable-ipr-selector
+ .tooth-selector svg.tooth:not(.ipr-restrict.active):not(.missing-tooth.active) g:not(:first-child) line {
    fill: #004eb2;
    stroke: #004eb2; }
  #teeth-grid.pdf .tooth-selector:has(+ .enable-ipr-selector .arrow-num) svg.tooth:not(.ipr-restrict.active):not(.missing-tooth.active) g:first-child path {
    fill: #87baff; }
  #teeth-grid.pdf .tooth-selector:has(+ .enable-ipr-selector .arrow-num) svg.tooth:not(.ipr-restrict.active):not(.missing-tooth.active) g:not(:first-child) path,
  #teeth-grid.pdf .tooth-selector:has(+ .enable-ipr-selector .arrow-num) svg.tooth:not(.ipr-restrict.active):not(.missing-tooth.active) g:not(:first-child) line {
    fill: #004eb2;
    stroke: #004eb2; }

.advanced-modules-modal-wrap *,
.advanced-modules-modal-wrap ::before,
.advanced-modules-modal-wrap ::after {
  box-sizing: border-box; }

.advanced-modules-modal-wrap button,
.advanced-modules-modal-wrap input,
.advanced-modules-modal-wrap optgroup,
.advanced-modules-modal-wrap select,
.advanced-modules-modal-wrap textarea {
  font-family: inherit;
  font-size: 14px;
  padding: 8px;
  line-height: inherit; }

.advanced-modules-modal-wrap .advanced-modules-modal {
  width: 55%;
  height: -moz-fit-content;
  height: fit-content;
  top: 0vh;
  max-width: 900px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 12px; }
  @media (max-width: 1070px) {
    .advanced-modules-modal-wrap .advanced-modules-modal {
      width: 75%;
      max-width: 75%; } }
  @media (max-width: 930px) {
    .advanced-modules-modal-wrap .advanced-modules-modal {
      width: 85%;
      max-width: 85%; } }
  @media (max-width: 839px) {
    .advanced-modules-modal-wrap .advanced-modules-modal {
      width: 95% !important;
      max-width: 95% !important; } }

.advanced-modules-modal-wrap .fade {
  transition: opacity 0.15s linear; }

@media (prefers-reduced-motion: reduce) {
  .advanced-modules-modal-wrap .fade {
    transition: none; } }

.advanced-modules-modal-wrap .fade:not(.show) {
  opacity: 0; }

.advanced-modules-modal-wrap .modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 16px;
  --bs-modal-margin: 8px;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 8px;
  --bs-modal-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(8px - 1px);
  --bs-modal-header-padding-x: 16px;
  --bs-modal-header-padding-y: 16px;
  --bs-modal-header-padding: 16px 16px;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 8px;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto !important;
  outline: 0; }

.advanced-modules-modal-wrap .modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none; }

.advanced-modules-modal-wrap .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px); }

@media (prefers-reduced-motion: reduce) {
  .advanced-modules-modal-wrap .modal.fade .modal-dialog {
    transition: none; } }

.advanced-modules-modal-wrap .modal.show .modal-dialog {
  transform: none; }

.advanced-modules-modal-wrap .modal.modal-static .modal-dialog {
  transform: scale(1.02); }

.advanced-modules-modal-wrap .modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }

.advanced-modules-modal-wrap .modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden; }

.advanced-modules-modal-wrap .modal-dialog-scrollable .modal-body {
  overflow-y: auto; }

.advanced-modules-modal-wrap .modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2); }

.advanced-modules-modal-wrap .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0; }

.advanced-modules-modal-wrap .modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg); }

.advanced-modules-modal-wrap .modal-backdrop.fade {
  opacity: 0; }

.advanced-modules-modal-wrap .modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity); }

.advanced-modules-modal-wrap .modal-header {
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: none !important;
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius); }

.advanced-modules-modal-wrap .modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto; }

.advanced-modules-modal-wrap .modal-title {
  margin-bottom: 0;
  margin-top: 0;
  width: 100%;
  line-height: var(--bs-modal-title-line-height); }

.advanced-modules-modal-wrap .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding); }

.advanced-modules-modal-wrap .modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius); }

.advanced-modules-modal-wrap .modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5); }

@media (min-width: 576px) {
  .advanced-modules-modal-wrap .modal {
    --bs-modal-margin: 28px;
    --bs-modal-box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); }
  .advanced-modules-modal-wrap .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto; }
  .advanced-modules-modal-wrap .modal-sm {
    --bs-modal-width: 300px; } }

@media (min-width: 992px) {
  .advanced-modules-modal-wrap .modal-lg,
  .advanced-modules-modal-wrap .modal-xl {
    --bs-modal-width: 800px; } }

@media (min-width: 1200px) {
  .advanced-modules-modal-wrap .modal-xl {
    --bs-modal-width: 1140px; } }

.advanced-modules-modal-wrap .modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }

.advanced-modules-modal-wrap .modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0; }

.advanced-modules-modal-wrap .modal-fullscreen .modal-header,
.advanced-modules-modal-wrap .modal-fullscreen .modal-footer {
  border-radius: 0; }

.advanced-modules-modal-wrap .modal-fullscreen .modal-body {
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .advanced-modules-modal-wrap .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-sm-down .modal-header,
  .advanced-modules-modal-wrap .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto; } }

@media (max-width: 767.98px) {
  .advanced-modules-modal-wrap .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-md-down .modal-header,
  .advanced-modules-modal-wrap .modal-fullscreen-md-down .modal-footer {
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-md-down .modal-body {
    overflow-y: auto; } }

@media (max-width: 991.98px) {
  .advanced-modules-modal-wrap .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-lg-down .modal-header,
  .advanced-modules-modal-wrap .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .advanced-modules-modal-wrap .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-xl-down .modal-header,
  .advanced-modules-modal-wrap .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto; } }

@media (max-width: 1399.98px) {
  .advanced-modules-modal-wrap .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-xxl-down .modal-header,
  .advanced-modules-modal-wrap .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto; } }

@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
*, ::before, ::after{
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ; }
::backdrop{
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ; }
.tw-pointer-events-none{
  pointer-events: none !important; }
.tw-absolute{
  position: absolute !important; }
.tw-relative{
  position: relative !important; }
.tw-inset-1{
  top: 4px !important;
  right: 4px !important;
  bottom: 4px !important;
  left: 4px !important; }
.tw-right-0{
  right: 0px !important; }
.tw-right-1{
  right: 4px !important; }
.tw-top-\[28\.3cm\]{
  top: 28.3cm !important; }
.tw-top-0{
  top: 0px !important; }
.tw-bottom-0{
  bottom: 0px !important; }
.tw-left-1\/4{
  left: 25% !important; }
.-tw-top-\[200px\]{
  top: -200px !important; }
.tw-top-20{
  top: 80px !important; }
.tw-right-\[calc\(64\%-1vw\)\]{
  right: calc(64% - 1vw) !important; }
.tw-top-\[-10px\]{
  top: -10px !important; }
.tw-bottom-\[-14px\]{
  bottom: -14px !important; }
.tw-right-\[2px\]{
  right: 2px !important; }
.tw-bottom-\[0px\]{
  bottom: 0px !important; }
.-tw-top-\[25\%\]{
  top: -25% !important; }
.tw-bottom-\[12px\]{
  bottom: 12px !important; }
.tw-top-\[12px\]{
  top: 12px !important; }
.tw-bottom-\[10px\]{
  bottom: 10px !important; }
.tw-top-\[10px\]{
  top: 10px !important; }
.tw-z-50{
  z-index: 50 !important; }
.tw-z-10{
  z-index: 10 !important; }
.tw-z-20{
  z-index: 20 !important; }
.tw-z-\[2000\]{
  z-index: 2000 !important; }
.tw-col-start-3{
  grid-column-start: 3 !important; }
.tw-m-auto{
  margin: auto !important; }
.tw-m-0{
  margin: 0px !important; }
.\!tw-m-\[0\]{
  margin: 0 !important; }
.tw-my-\[40px\]{
  margin-top: 40px !important;
  margin-bottom: 40px !important; }
.tw-mx-1{
  margin-left: 4px !important;
  margin-right: 4px !important; }
.tw-my-\[2px\]{
  margin-top: 2px !important;
  margin-bottom: 2px !important; }
.tw-mx-\[2px\]{
  margin-left: 2px !important;
  margin-right: 2px !important; }
.tw-my-\[1px\]{
  margin-top: 1px !important;
  margin-bottom: 1px !important; }
.tw-my-auto{
  margin-top: auto !important;
  margin-bottom: auto !important; }
.tw-mx-4{
  margin-left: 16px !important;
  margin-right: 16px !important; }
.tw-my-2{
  margin-top: 8px !important;
  margin-bottom: 8px !important; }
.tw-mt-\[40px\]{
  margin-top: 40px !important; }
.tw-mt-\[20px\]{
  margin-top: 20px !important; }
.\!tw-mb-\[20px\]{
  margin-bottom: 20px !important; }
.\!tw-mb-\[35px\]{
  margin-bottom: 35px !important; }
.\!tw-mb-\[50px\]{
  margin-bottom: 50px !important; }
.\!tw-mb-\[65px\]{
  margin-bottom: 65px !important; }
.\!tw-mb-\[80px\]{
  margin-bottom: 80px !important; }
.\!tw-mb-\[95px\]{
  margin-bottom: 95px !important; }
.tw-mt-3{
  margin-top: 12px !important; }
.tw-mr-1{
  margin-right: 4px !important; }
.tw-ml-1{
  margin-left: 4px !important; }
.tw-ml-0{
  margin-left: 0px !important; }
.tw-mr-0{
  margin-right: 0px !important; }
.tw-mt-5{
  margin-top: 20px !important; }
.tw-mr-\[12px\]{
  margin-right: 12px !important; }
.tw-mt-1{
  margin-top: 4px !important; }
.tw-ml-2{
  margin-left: 8px !important; }
.tw-mb-4{
  margin-bottom: 16px !important; }
.tw-mb-1{
  margin-bottom: 4px !important; }
.tw-mt-7{
  margin-top: 28px !important; }
.tw-mt-10{
  margin-top: 40px !important; }
.tw-ml-5{
  margin-left: 20px !important; }
.tw-mt-2{
  margin-top: 8px !important; }
.tw-mt-6{
  margin-top: 24px !important; }
.tw-mb-2{
  margin-bottom: 8px !important; }
.tw-mb-0{
  margin-bottom: 0px !important; }
.tw-block{
  display: block !important; }
.tw-inline-block{
  display: inline-block !important; }
.tw-flex{
  display: flex !important; }
.tw-inline-flex{
  display: inline-flex !important; }
.tw-grid{
  display: grid !important; }
.tw-hidden{
  display: none !important; }
.tw-aspect-square{
  aspect-ratio: 1 / 1 !important; }
.tw-h-4{
  height: 16px !important; }
.tw-h-full{
  height: 100% !important; }
.tw-h-0{
  height: 0px !important; }
.tw-h-auto{
  height: auto !important; }
.tw-h-\[60px\]{
  height: 60px !important; }
.tw-h-\[29\.6cm\]{
  height: 29.6cm !important; }
.tw-h-fit{
  height: -moz-fit-content !important;
  height: fit-content !important; }
.tw-h-\[calc\(100\%\+10px\)\]{
  height: calc(100% + 10px) !important; }
.tw-h-\[calc\(100\%\+14px\)\]{
  height: calc(100% + 14px) !important; }
.tw-max-h-\[45px\]{
  max-height: 45px !important; }
.tw-max-h-\[20px\]{
  max-height: 20px !important; }
.tw-max-h-\[85px\]{
  max-height: 85px !important; }
.tw-min-h-\[100px\]{
  min-height: 100px !important; }
.tw-min-h-\[224px\]{
  min-height: 224px !important; }
.tw-w-full{
  width: 100% !important; }
.tw-w-fit{
  width: -moz-fit-content !important;
  width: fit-content !important; }
.tw-w-0{
  width: 0px !important; }
.tw-w-\[12px\]{
  width: 12px !important; }
.tw-w-\[13px\]{
  width: 13px !important; }
.tw-w-\[18px\]{
  width: 18px !important; }
.tw-w-\[21cm\]{
  width: 21cm !important; }
.tw-w-\[calc\(100\%-25px\)\]{
  width: calc(100% - 25px) !important; }
.tw-w-screen{
  width: 100vw !important; }
.tw-w-\[calc\(100\%\+4px\)\]{
  width: calc(100% + 4px) !important; }
.tw-w-\[4px\]{
  width: 4px !important; }
.tw-w-\[2vw\]{
  width: 2vw !important; }
.tw-w-\[10px\]{
  width: 10px !important; }
.tw-w-\[400\%\]{
  width: 400% !important; }
.tw-min-w-\[100px\]{
  min-width: 100px !important; }
.tw-max-w-\[calc\(800px\+5rem\)\]{
  max-width: calc(800px + 80px) !important; }
.tw-max-w-\[770px\]{
  max-width: 770px !important; }
.tw-max-w-full{
  max-width: 100% !important; }
.tw-max-w-md{
  max-width: 448px !important; }
.tw-max-w-\[320px\]{
  max-width: 320px !important; }
.tw-max-w-\[300px\]{
  max-width: 300px !important; }
.tw-max-w-\[calc\(100\%-25px\)\]{
  max-width: calc(100% - 25px) !important; }
.tw-max-w-\[20px\]{
  max-width: 20px !important; }
.tw-max-w-xs{
  max-width: 320px !important; }
.tw-max-w-\[270px\]{
  max-width: 270px !important; }
.tw-flex-1{
  flex: 1 1 0% !important; }
.tw-flex-\[4\]{
  flex: 4 !important; }
.tw-basis-1\/2{
  flex-basis: 50% !important; }
.tw-rotate-180{
  --tw-rotate: 180deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important; }
.tw-rotate-\[-100deg\]{
  --tw-rotate: -100deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important; }
.tw-rotate-\[-50deg\]{
  --tw-rotate: -50deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important; }
.tw-cursor-pointer{
  cursor: pointer !important; }
.tw-cursor-not-allowed{
  cursor: not-allowed !important; }
.tw-resize-none{
  resize: none !important; }
.tw-appearance-none{
  appearance: none !important; }
.tw-break-before-page{
  break-before: page !important; }
.tw-break-inside-avoid{
  break-inside: avoid !important; }
.tw-grid-cols-\[repeat\(3\,minmax\(auto\,250px\)\)\]{
  grid-template-columns: repeat(3,minmax(auto,250px)) !important; }
.tw-grid-cols-3{
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important; }
.tw-grid-cols-2{
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important; }
.tw-grid-cols-\[1fr\,2fr\]{
  grid-template-columns: 1fr 2fr !important; }
.tw-grid-cols-\[auto\,1fr\]{
  grid-template-columns: auto 1fr !important; }
.tw-grid-cols-1{
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important; }
.tw-grid-cols-\[1fr_1fr_1fr_100px_1fr_160px\]{
  grid-template-columns: 1fr 1fr 1fr 100px 1fr 160px !important; }
.tw-grid-cols-\[3fr\,4fr\]{
  grid-template-columns: 3fr 4fr !important; }
.tw-flex-row{
  flex-direction: row !important; }
.tw-flex-col{
  flex-direction: column !important; }
.tw-flex-col-reverse{
  flex-direction: column-reverse !important; }
.tw-flex-wrap{
  flex-wrap: wrap !important; }
.tw-place-items-center{
  place-items: center !important; }
.tw-content-end{
  align-content: flex-end !important; }
.tw-items-start{
  align-items: flex-start !important; }
.tw-items-end{
  align-items: flex-end !important; }
.tw-items-center{
  align-items: center !important; }
.tw-items-baseline{
  align-items: baseline !important; }
.tw-justify-start{
  justify-content: flex-start !important; }
.tw-justify-end{
  justify-content: flex-end !important; }
.tw-justify-center{
  justify-content: center !important; }
.tw-justify-between{
  justify-content: space-between !important; }
.tw-justify-around{
  justify-content: space-around !important; }
.tw-gap-2{
  gap: 8px !important; }
.tw-gap-4{
  gap: 16px !important; }
.tw-gap-\[2px\]{
  gap: 2px !important; }
.tw-gap-\[12px\]{
  gap: 12px !important; }
.tw-gap-\[10px\]{
  gap: 10px !important; }
.tw-gap-\[14px\]{
  gap: 14px !important; }
.tw-gap-\[5px\]{
  gap: 5px !important; }
.tw-gap-3{
  gap: 12px !important; }
.tw-gap-\[4px\]{
  gap: 4px !important; }
.tw-gap-6{
  gap: 24px !important; }
.tw-gap-1{
  gap: 4px !important; }
.tw-gap-5{
  gap: 20px !important; }
.tw-gap-\[3px\]{
  gap: 3px !important; }
.tw-place-self-start{
  place-self: start !important; }
.tw-place-self-end{
  place-self: end !important; }
.tw-place-self-center{
  place-self: center !important; }
.tw-self-start{
  align-self: flex-start !important; }
.tw-self-end{
  align-self: flex-end !important; }
.tw-self-center{
  align-self: center !important; }
.tw-justify-self-center{
  justify-self: center !important; }
.tw-overflow-hidden{
  overflow: hidden !important; }
.tw-text-ellipsis{
  text-overflow: ellipsis !important; }
.tw-whitespace-nowrap{
  white-space: nowrap !important; }
.tw-whitespace-pre-wrap{
  white-space: pre-wrap !important; }
.tw-break-words{
  overflow-wrap: break-word !important; }
.tw-rounded-md{
  border-radius: 6px !important; }
.tw-rounded{
  border-radius: 4px !important; }
.tw-rounded-\[2px\]{
  border-radius: 2px !important; }
.tw-rounded-\[60px\]{
  border-radius: 60px !important; }
.tw-rounded-\[5px\]{
  border-radius: 5px !important; }
.tw-rounded-lg{
  border-radius: 8px !important; }
.tw-rounded-\[25\%\]{
  border-radius: 25% !important; }
.\!tw-rounded-lg{
  border-radius: 8px !important; }
.\!tw-rounded{
  border-radius: 4px !important; }
.tw-border-\[0\]{
  border-width: 0 !important; }
.tw-border-0{
  border-width: 0px !important; }
.tw-border-2{
  border-width: 2px !important; }
.tw-border-\[1px\]{
  border-width: 1px !important; }
.tw-border{
  border-width: 1px !important; }
.tw-border-t-\[1px\]{
  border-top-width: 1px !important; }
.tw-border-r-\[1px\]{
  border-right-width: 1px !important; }
.tw-border-b-\[1px\]{
  border-bottom-width: 1px !important; }
.tw-border-t-0{
  border-top-width: 0px !important; }
.tw-border-b-2{
  border-bottom-width: 2px !important; }
.tw-border-b{
  border-bottom-width: 1px !important; }
.tw-border-t-\[0px\]{
  border-top-width: 0px !important; }
.tw-border-l-\[0px\]{
  border-left-width: 0px !important; }
.tw-border-r-\[0px\]{
  border-right-width: 0px !important; }
.tw-border-b-0{
  border-bottom-width: 0px !important; }
.tw-border-t-\[2px\]{
  border-top-width: 2px !important; }
.tw-border-solid{
  border-style: solid !important; }
.tw-border-dashed{
  border-style: dashed !important; }
.\!tw-border-none{
  border-style: none !important; }
.tw-border-none{
  border-style: none !important; }
.tw-border-\[\#AFAFAF\]{
  --tw-border-opacity: 1 !important;
  border-color: rgb(175 175 175 / var(--tw-border-opacity)) !important; }
.tw-border-\[\#C8C8C8CA\]{
  border-color: #C8C8C8CA !important; }
.tw-border-black{
  --tw-border-opacity: 1 !important;
  border-color: rgb(0 0 0 / var(--tw-border-opacity)) !important; }
.tw-border-\[\#3084F0\]{
  --tw-border-opacity: 1 !important;
  border-color: rgb(48 132 240 / var(--tw-border-opacity)) !important; }
.tw-border-transparent{
  border-color: transparent !important; }
.tw-border-\[\#EAECEF\]{
  --tw-border-opacity: 1 !important;
  border-color: rgb(234 236 239 / var(--tw-border-opacity)) !important; }
.tw-border-\[\#F9F9F9\]{
  --tw-border-opacity: 1 !important;
  border-color: rgb(249 249 249 / var(--tw-border-opacity)) !important; }
.tw-border-gray-300{
  --tw-border-opacity: 1 !important;
  border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important; }
.tw-border-\[\#d3d3d3\]{
  --tw-border-opacity: 1 !important;
  border-color: rgb(211 211 211 / var(--tw-border-opacity)) !important; }
.tw-border-\[\#1D4655\]{
  --tw-border-opacity: 1 !important;
  border-color: rgb(29 70 85 / var(--tw-border-opacity)) !important; }
.tw-bg-gray-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity)) !important; }
.tw-bg-white{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important; }
.tw-bg-\[\#FAAD14\]{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(250 173 20 / var(--tw-bg-opacity)) !important; }
.tw-bg-\[\#3084F0\]{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(48 132 240 / var(--tw-bg-opacity)) !important; }
.tw-bg-black{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important; }
.tw-bg-\[\#78b3ff69\]{
  background-color: #78b3ff69 !important; }
.tw-bg-\[\#3084F00D\]{
  background-color: #3084F00D !important; }
.tw-bg-\[\#ffff\]{
  background-color: #ffff !important; }
.tw-bg-\[\#F7F6F9\]{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(247 246 249 / var(--tw-bg-opacity)) !important; }
.\!tw-bg-\[\#f2f4f5\]{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(242 244 245 / var(--tw-bg-opacity)) !important; }
.tw-bg-\[\#C8C8CA\]{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(200 200 202 / var(--tw-bg-opacity)) !important; }
.tw-bg-\[\#F5F5F5\]{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity)) !important; }
.tw-bg-\[\#1D4655\]{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(29 70 85 / var(--tw-bg-opacity)) !important; }
.tw-bg-opacity-80{
  --tw-bg-opacity: 0.8 !important; }
.tw-object-scale-down{
  object-fit: scale-down !important; }
.tw-p-\[20px\]{
  padding: 20px !important; }
.tw-p-2{
  padding: 8px !important; }
.tw-p-\[3px\]{
  padding: 3px !important; }
.tw-p-\[4px\]{
  padding: 4px !important; }
.tw-p-3{
  padding: 12px !important; }
.tw-p-4{
  padding: 16px !important; }
.tw-p-1{
  padding: 4px !important; }
.tw-p-10{
  padding: 40px !important; }
.tw-p-0{
  padding: 0px !important; }
.tw-px-\[40px\]{
  padding-left: 40px !important;
  padding-right: 40px !important; }
.tw-px-3{
  padding-left: 12px !important;
  padding-right: 12px !important; }
.tw-py-3{
  padding-top: 12px !important;
  padding-bottom: 12px !important; }
.tw-py-2{
  padding-top: 8px !important;
  padding-bottom: 8px !important; }
.tw-px-\[1\.5px\]{
  padding-left: 1.5px !important;
  padding-right: 1.5px !important; }
.tw-py-\[0px\]{
  padding-top: 0px !important;
  padding-bottom: 0px !important; }
.tw-px-\[5px\]{
  padding-left: 5px !important;
  padding-right: 5px !important; }
.tw-px-\[2px\]{
  padding-left: 2px !important;
  padding-right: 2px !important; }
.tw-px-4{
  padding-left: 16px !important;
  padding-right: 16px !important; }
.tw-px-8{
  padding-left: 32px !important;
  padding-right: 32px !important; }
.tw-py-\[5px\]{
  padding-top: 5px !important;
  padding-bottom: 5px !important; }
.tw-px-\[10px\]{
  padding-left: 10px !important;
  padding-right: 10px !important; }
.tw-px-1{
  padding-left: 4px !important;
  padding-right: 4px !important; }
.tw-py-4{
  padding-top: 16px !important;
  padding-bottom: 16px !important; }
.tw-px-2{
  padding-left: 8px !important;
  padding-right: 8px !important; }
.tw-px-9{
  padding-left: 36px !important;
  padding-right: 36px !important; }
.tw-py-6{
  padding-top: 24px !important;
  padding-bottom: 24px !important; }
.tw-py-1{
  padding-top: 4px !important;
  padding-bottom: 4px !important; }
.tw-px-\[1px\]{
  padding-left: 1px !important;
  padding-right: 1px !important; }
.tw-px-5{
  padding-left: 20px !important;
  padding-right: 20px !important; }
.\!tw-pt-\[105px\]{
  padding-top: 105px !important; }
.\!tw-pb-\[105px\]{
  padding-bottom: 105px !important; }
.\!tw-pt-\[80px\]{
  padding-top: 80px !important; }
.\!tw-pb-\[80px\]{
  padding-bottom: 80px !important; }
.\!tw-pt-\[45px\]{
  padding-top: 45px !important; }
.\!tw-pb-\[45px\]{
  padding-bottom: 45px !important; }
.\!tw-pt-\[20px\]{
  padding-top: 20px !important; }
.\!tw-pb-\[20px\]{
  padding-bottom: 20px !important; }
.\!tw-pb-\[35px\]{
  padding-bottom: 35px !important; }
.\!tw-pb-\[50px\]{
  padding-bottom: 50px !important; }
.\!tw-pb-\[65px\]{
  padding-bottom: 65px !important; }
.\!tw-pb-\[95px\]{
  padding-bottom: 95px !important; }
.\!tw-pt-\[190px\]{
  padding-top: 190px !important; }
.\!tw-pb-\[190px\]{
  padding-bottom: 190px !important; }
.\!tw-pt-\[160px\]{
  padding-top: 160px !important; }
.\!tw-pb-\[160px\]{
  padding-bottom: 160px !important; }
.\!tw-pt-\[130px\]{
  padding-top: 130px !important; }
.\!tw-pb-\[130px\]{
  padding-bottom: 130px !important; }
.\!tw-pt-\[100px\]{
  padding-top: 100px !important; }
.\!tw-pb-\[100px\]{
  padding-bottom: 100px !important; }
.\!tw-pt-\[70px\]{
  padding-top: 70px !important; }
.\!tw-pb-\[70px\]{
  padding-bottom: 70px !important; }
.\!tw-pt-\[320px\]{
  padding-top: 320px !important; }
.\!tw-pb-\[320px\]{
  padding-bottom: 320px !important; }
.\!tw-pt-\[260px\]{
  padding-top: 260px !important; }
.\!tw-pb-\[260px\]{
  padding-bottom: 260px !important; }
.\!tw-pt-\[120px\]{
  padding-top: 120px !important; }
.\!tw-pb-\[120px\]{
  padding-bottom: 120px !important; }
.\!tw-pt-\[30px\]{
  padding-top: 30px !important; }
.\!tw-pb-\[30px\]{
  padding-bottom: 30px !important; }
.tw-pt-10{
  padding-top: 40px !important; }
.tw-pb-2{
  padding-bottom: 8px !important; }
.tw-pt-\[30px\]{
  padding-top: 30px !important; }
.tw-pb-10{
  padding-bottom: 40px !important; }
.tw-pt-3{
  padding-top: 12px !important; }
.tw-pb-4{
  padding-bottom: 16px !important; }
.tw-pb-1{
  padding-bottom: 4px !important; }
.tw-pl-1{
  padding-left: 4px !important; }
.tw-pt-2{
  padding-top: 8px !important; }
.tw-pb-6{
  padding-bottom: 24px !important; }
.tw-pb-3{
  padding-bottom: 12px !important; }
.tw-pt-4{
  padding-top: 16px !important; }
.tw-pb-5{
  padding-bottom: 20px !important; }
.tw-pr-2{
  padding-right: 8px !important; }
.tw-pt-7{
  padding-top: 28px !important; }
.tw-text-center{
  text-align: center !important; }
.tw-text-start{
  text-align: start !important; }
.tw-text-end{
  text-align: end !important; }
.tw-align-middle{
  vertical-align: middle !important; }
.\!tw-font-sans{
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important; }
.tw-text-lg{
  font-size: 18px !important;
  line-height: 28px !important; }
.tw-text-xs{
  font-size: 12px !important;
  line-height: 16px !important; }
.tw-text-\[10px\]{
  font-size: 10px !important; }
.tw-text-\[18px\]{
  font-size: 18px !important; }
.tw-text-\[13px\]{
  font-size: 13px !important; }
.tw-text-\[16px\]{
  font-size: 16px !important; }
.tw-text-\[14px\]{
  font-size: 14px !important; }
.tw-text-xl{
  font-size: 20px !important;
  line-height: 28px !important; }
.tw-text-base{
  font-size: 16px !important;
  line-height: 24px !important; }
.tw-text-sm{
  font-size: 14px !important;
  line-height: 20px !important; }
.tw-text-2xl{
  font-size: 24px !important;
  line-height: 32px !important; }
.tw-text-\[12px\]{
  font-size: 12px !important; }
.tw-text-\[25px\]{
  font-size: 25px !important; }
.\!tw-text-\[11px\]{
  font-size: 11px !important; }
.tw-text-\[15px\]{
  font-size: 15px !important; }
.tw-text-\[11px\]{
  font-size: 11px !important; }
.tw-font-\[700\]{
  font-weight: 700 !important; }
.tw-font-bold{
  font-weight: 700 !important; }
.tw-font-medium{
  font-weight: 500 !important; }
.tw-font-normal{
  font-weight: 400 !important; }
.tw-font-semibold{
  font-weight: 600 !important; }
.tw-font-\[500\]{
  font-weight: 500 !important; }
.tw-font-\[600\]{
  font-weight: 600 !important; }
.tw-font-black{
  font-weight: 900 !important; }
.tw-capitalize{
  text-transform: capitalize !important; }
.tw-leading-3{
  line-height: 12px !important; }
.tw-leading-\[0px\]{
  line-height: 0px !important; }
.tw-leading-5{
  line-height: 20px !important; }
.tw-text-black{
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important; }
.tw-text-\[\#3084F0\]{
  --tw-text-opacity: 1 !important;
  color: rgb(48 132 240 / var(--tw-text-opacity)) !important; }
.tw-text-white{
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important; }
.tw-text-\[\#898A8D\]{
  --tw-text-opacity: 1 !important;
  color: rgb(137 138 141 / var(--tw-text-opacity)) !important; }
.tw-text-red-500{
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-text-opacity)) !important; }
.tw-text-\[\#2E2E2F\]{
  --tw-text-opacity: 1 !important;
  color: rgb(46 46 47 / var(--tw-text-opacity)) !important; }
.tw-text-\[\#1d4655\]{
  --tw-text-opacity: 1 !important;
  color: rgb(29 70 85 / var(--tw-text-opacity)) !important; }
.tw-text-\[\#fc4b6c\]{
  --tw-text-opacity: 1 !important;
  color: rgb(252 75 108 / var(--tw-text-opacity)) !important; }
.tw-text-\[\#ff0000\]{
  --tw-text-opacity: 1 !important;
  color: rgb(255 0 0 / var(--tw-text-opacity)) !important; }
.tw-text-\[\#00c7b1\]{
  --tw-text-opacity: 1 !important;
  color: rgb(0 199 177 / var(--tw-text-opacity)) !important; }
.tw-text-\[\#1D4655\]{
  --tw-text-opacity: 1 !important;
  color: rgb(29 70 85 / var(--tw-text-opacity)) !important; }
.tw-underline{
  text-decoration-line: underline !important; }
.tw-shadow-\[0_2px_3px_rgba\(0\,0\,0\,0\.18\)\]{
  --tw-shadow: 0 2px 3px rgba(0,0,0,0.18) !important;
  --tw-shadow-colored: 0 2px 3px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important; }
.tw-shadow-\[0px_8px_11px_rgba\(0\,0\,0\,0\.25\)\]{
  --tw-shadow: 0px 8px 11px rgba(0,0,0,0.25) !important;
  --tw-shadow-colored: 0px 8px 11px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important; }
.tw-outline-2{
  outline-width: 2px !important; }
/*Theme Colors*/
/*Topbar Colors*/
/*Sidebar Colors*/
/*Boxed layout width*/
/*Shadow*/
/* transitions */
/* Dark transparent bg */
/* ***************************** */
/******************************
/* Color system*/
.advance_main_grid_V4_PK {
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, 250px));
  grid-gap: 1em;
  text-align: center;
  align-items: center; }
  @media (max-width: 1330px) {
    .advance_main_grid_V4_PK {
      grid-template-columns: repeat(4, minmax(auto, 200px)); } }
  @media (max-width: 839px) {
    .advance_main_grid_V4_PK {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 1em;
      grid-column-gap: 8px; } }

.advance_main_grid_V3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em; }
  @media (max-width: 839px) {
    .advance_main_grid_V3 {
      grid-template-columns: 1fr;
      grid-row-gap: 1em;
      grid-column-gap: 8px; } }

.subhead-PK {
  font-family: Poppins !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important; }
  @media (max-width: 839px) {
    .subhead-PK {
      font-family: Poppins !important;
      font-size: 12px !important;
      line-height: 20px !important;
      font-weight: 400 !important; } }

.header_grid_pk {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: baseline;
  grid-column-gap: 8px;
  margin: 16px 16px 4px 16px; }

.close_pk {
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  border: none;
  background-color: #fff; }

.header_title_pk {
  font-weight: 700;
  font-size: 16px; }

.header_note_pk {
  font-weight: 600;
  font-size: 12px;
  color: #898a8d;
  margin: 0px 16px; }

.save-module-btn-pk {
  align-self: center;
  text-align: end; }
  @media (max-width: 839px) {
    .save-module-btn-pk button {
      width: 100% !important; } }

.input-container-pk {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin: 12px 0px;
  padding: 0 2px; }

.form-check-input-pk {
  width: 1.3em !important;
  height: 1.3em !important; }

.form-check-input-pk:checked {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important; }

input.button-text-select_PK,
label.button-text-select_PK {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #898a8d;
  margin-right: 8px; }
  @media (max-width: 839px) {
    input.button-text-select_PK,
    label.button-text-select_PK {
      font-size: 12px; } }

.modules_titles_pk {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2e2e2f; }
  @media (max-width: 839px) {
    .modules_titles_pk {
      font-size: 12px; } }

.attachments-step-title-pk {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2e2e2f; }

.attachments-step-note-pk {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #898a8d; }

.custome-input-text-pk {
  height: 80px;
  background: #f5f5f5 !important;
  border-radius: 8px;
  width: 100%;
  border: none; }
  @media (max-width: 839px) {
    .custome-input-text-pk {
      width: 100%; } }

.custome-input-midline-pk {
  width: 250px !important;
  height: 40px;
  background: #f5f5f5 !important;
  border-radius: 8px;
  border: none; }
  @media (max-width: 839px) {
    .custome-input-midline-pk {
      width: 100%; } }

input.pk::placeholder {
  opacity: 0.5;
  color: #c8c8ca !important; }

.absolute-container-layout-PK {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: fill;
  right: 0;
  display: grid;
  place-items: center;
  padding: 24px;
  color: #000;
  font-weight: bold;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: #fff;
  opacity: 0.8; }

.none_pk {
  display: none; }

.absolute-text-layout-PK {
  position: absolute;
  top: 30%; }

.radio-group-container-pk {
  display: grid;
  padding-top: 16px;
  grid-template-columns: 1fr auto 1fr; }
  @media (max-width: 839px) {
    .radio-group-container-pk {
      grid-template-columns: 1fr; } }

.active-border-pk {
  border: 2px solid var(--primary);
  border-radius: 8px; }

.border-pk {
  border: 1px solid #d5d8df;
  border-radius: 8px; }

.radio-label-pk {
  font-size: 12px; }

@media (max-width: 839px) {
  .teeth-padding-mobile-pk {
    padding-bottom: 24px; } }

.input-title-pk {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding: 4px 0px;
  letter-spacing: -0.0001em;
  color: #2e2e2f; }

.scroll-pk {
  height: 56vh;
  max-height: 56vh;
  overflow: auto; }

.buttons-confirmation-container-pk {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  float: right; }
  @media (max-width: 839px) {
    .buttons-confirmation-container-pk {
      display: grid;
      grid-row-gap: 12px;
      grid-template-columns: 1fr;
      float: unset; } }

.confirmation-margin-pk {
  margin-left: 8px; }
  @media (max-width: 839px) {
    .confirmation-margin-pk {
      margin-left: 0px; } }

.c-modal-pk {
  top: 20%;
  border: none !important;
  color: #2e2e2f;
  font-size: 14px; }
  .c-modal-pk .modal-header {
    border: none !important;
    padding-bottom: 8px; }

.c-border-light-pk {
  border: none;
  border-radius: 16px;
  top: 10%;
  width: 40% !important;
  max-width: 40% !important; }
  @media (max-width: 839px) {
    .c-border-light-pk {
      top: 5%;
      width: 95% !important;
      max-width: 95% !important; } }
  .c-border-light-pk .modal-content {
    border: none; }
    @media (max-width: 839px) {
      .c-border-light-pk .modal-content {
        max-width: 900px !important;
        border-radius: 12px !important;
        max-width: 90% !important;
        display: block;
        margin: auto; } }

.popup-actions-grid-pk {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  grid-gap: 12px; }

.module-button-grid-pk {
  display: grid;
  grid-column-gap: 12px !important;
  grid-template-columns: 1fr 5fr; }

.placeholder_pk {
  position: relative;
  width: -moz-fit-content;
  width: fit-content; }

.placeholder_pk::after {
  position: absolute;
  right: 34px;
  top: 8px;
  content: attr(data-placeholder);
  pointer-events: none;
  opacity: 0.3; }

.providerAdvancedModulesAdmin {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  display: grid; }

.passiveGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }
  @media (max-width: 839px) {
    .passiveGrid {
      grid-template-columns: 1fr; } }

.primary_color {
  color: var(--primary) !important; }

.primary-btn-pk {
  border: 2px solid var(--primary) !important;
  color: var(--secondary) !important;
  background-color: var(--primary) !important;
  border-radius: 24px !important;
  box-shadow: none !important;
  min-width: 150px !important;
  font-family: Poppins;
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 500 !important; }
  .primary-btn-pk:hover {
    border: 2px solid var(--primary_hover) !important;
    color: var(--secondary) !important;
    background-color: var(--primary_hover) !important;
    border-radius: 24px !important;
    box-shadow: none !important; }
  .primary-btn-pk.disabled, .primary-btn-pk:disabled {
    pointer-events: none !important;
    border: 2px solid lightgray !important;
    background-color: lightgray !important;
    box-shadow: none !important; }
  .primary-btn-pk:active {
    background: var(--primary) !important;
    border: 2px solid var(--primary) !important;
    color: var(--secondary) !important;
    box-shadow: none !important; }
  @media screen and (max-width: 839px) {
    .primary-btn-pk {
      font-family: Poppins !important;
      font-size: 14px !important;
      line-height: 22px !important;
      font-weight: 500 !important;
      min-width: 100px !important; } }

.secondary-btn-pk {
  border: 2px solid var(--primary) !important;
  box-sizing: border-box !important;
  color: var(--primary) !important;
  background-color: var(--secondary) !important;
  border-radius: 24px !important;
  box-shadow: none !important;
  min-width: 100px !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 500 !important; }
  .secondary-btn-pk:hover {
    border: 2px solid var(--primary_hover) !important;
    box-sizing: border-box !important;
    color: var(--primary_hover) !important;
    background-color: var(--secondary) !important;
    border-radius: 24px !important;
    box-shadow: none !important; }
  .secondary-btn-pk.disabled, .secondary-btn-pk:disabled {
    pointer-events: none !important;
    color: lightgray !important;
    border: 2px solid lightgray !important;
    background-color: white !important;
    box-shadow: none !important; }
  .secondary-btn-pk:active {
    background: var(--secondary) !important;
    border: 2px solid var(--primary) !important;
    color: var(--primary) !important;
    box-shadow: none !important; }
  @media screen and (max-width: 839px) {
    .secondary-btn-pk {
      min-width: 100px !important;
      width: 100% !important;
      font-family: Poppins !important;
      font-size: 14px !important;
      line-height: 22px !important;
      font-weight: 500 !important; } }

/* margin 8px */
.cm1 {
  margin: 8px; }

.cml1 {
  margin-left: 8px; }

.cmr1 {
  margin-right: 8px; }

.cmt1 {
  margin-top: 8px; }

.cmb1 {
  margin-bottom: 8px; }

.cmx1 {
  margin-left: 8px;
  margin-right: 8px; }

.cmy1 {
  margin-top: 8px;
  margin-bottom: 8px; }

/* margin 16px */
.cm2 {
  margin: 16px; }

.cml2 {
  margin-left: 16px; }

.cmr2 {
  margin-right: 16px; }

.cmt2 {
  margin-top: 16px; }

.cmb2 {
  margin-bottom: 16px; }

.cmx2 {
  margin-left: 16px;
  margin-right: 16px; }

.cmy2 {
  margin-top: 16px;
  margin-bottom: 16px; }

/* margin 24px */
.cm3 {
  margin: 24px; }

.cml3 {
  margin-left: 24px; }

.cmr3 {
  margin-right: 24px; }

.cmt3 {
  margin-top: 24px; }

.cmb3 {
  margin-bottom: 24px; }

.cmx3 {
  margin-left: 24px;
  margin-right: 24px; }

.cmy3 {
  margin-top: 24px;
  margin-bottom: 24px; }

/* margin 32px */
.cm4 {
  margin: 32px; }

.cml4 {
  margin-left: 32px; }

.cmr4 {
  margin-right: 32px; }

.cmt4 {
  margin-top: 32px; }

.cmb4 {
  margin-bottom: 32px; }

.cmx4 {
  margin-left: 32px;
  margin-right: 32px; }

.cmy4 {
  margin-top: 32px;
  margin-bottom: 32px; }

/* margin 40px */
.cm5 {
  margin: 40px; }

.cml5 {
  margin-left: 40px; }

.cmr5 {
  margin-right: 40px; }

.cmt5 {
  margin-top: 40px; }

.cmb5 {
  margin-bottom: 40px; }

.cmx5 {
  margin-left: 40px;
  margin-right: 40px; }

.cmy5 {
  margin-top: 40px;
  margin-bottom: 40px; }

/* margin 48px */
.cm6 {
  margin: 48px; }

.cml6 {
  margin-left: 48px; }

.cmr6 {
  margin-right: 48px; }

.cmt6 {
  margin-top: 48px; }

.cmb6 {
  margin-bottom: 48px; }

.cmx6 {
  margin-left: 48px;
  margin-right: 48px; }

.cmy6 {
  margin-top: 48px;
  margin-bottom: 48px; }

/* margin 56px */
.cm7 {
  margin: 56px; }

.cml7 {
  margin-left: 56px; }

.cmr7 {
  margin-right: 56px; }

.cmt7 {
  margin-top: 56px; }

.cmb7 {
  margin-bottom: 56px; }

.cmx7 {
  margin-left: 56px;
  margin-right: 56px; }

.cmy7 {
  margin-top: 56px;
  margin-bottom: 56px; }

/* margin 64px */
.cm8 {
  margin: 64px; }

.cml8 {
  margin-left: 64px; }

.cmr8 {
  margin-right: 64px; }

.cmt8 {
  margin-top: 64px; }

.cmb8 {
  margin-bottom: 64px; }

.cmx8 {
  margin-left: 64px;
  margin-right: 64px; }

.cmy8 {
  margin-top: 64px;
  margin-bottom: 64px; }

/* margin 72px */
.cm9 {
  margin: 72px; }

.cml9 {
  margin-left: 72px; }

.cmr9 {
  margin-right: 72px; }

.cmt9 {
  margin-top: 72px; }

.cmb9 {
  margin-bottom: 72px; }

.cmx9 {
  margin-left: 72px;
  margin-right: 72px; }

.cmy9 {
  margin-top: 72px;
  margin-bottom: 72px; }

/* margin 80px */
.cm10 {
  margin: 80px; }

.cml10 {
  margin-left: 80px; }

.cmr10 {
  margin-right: 80px; }

.cmt10 {
  margin-top: 80px; }

.cmb10 {
  margin-bottom: 80px; }

.cmx10 {
  margin-left: 80px;
  margin-right: 80px; }

.cmy10 {
  margin-top: 80px;
  margin-bottom: 80px; }

/* margin 88px */
.cm11 {
  margin: 88px; }

.cml11 {
  margin-left: 88px; }

.cmr11 {
  margin-right: 88px; }

.cmt11 {
  margin-top: 88px; }

.cmb11 {
  margin-bottom: 88px; }

.cmx11 {
  margin-left: 88px;
  margin-right: 88px; }

.cmy11 {
  margin-top: 88px;
  margin-bottom: 88px; }

/* margin 96px */
.cm12 {
  margin: 96px; }

.cml12 {
  margin-left: 96px; }

.cmr12 {
  margin-right: 96px; }

.cmt12 {
  margin-top: 96px; }

.cmb12 {
  margin-bottom: 96px; }

.cmx12 {
  margin-left: 96px;
  margin-right: 96px; }

.cmy12 {
  margin-top: 96px;
  margin-bottom: 96px; }

/* margin 104px */
.cm13 {
  margin: 104px; }

.cml13 {
  margin-left: 104px; }

.cmr13 {
  margin-right: 104px; }

.cmt13 {
  margin-top: 104px; }

.cmb13 {
  margin-bottom: 104px; }

.cmx13 {
  margin-left: 104px;
  margin-right: 104px; }

.cmy13 {
  margin-top: 104px;
  margin-bottom: 104px; }

/* margin 112px */
.cm14 {
  margin: 112px; }

.cml14 {
  margin-left: 112px; }

.cmr14 {
  margin-right: 112px; }

.cmt14 {
  margin-top: 112px; }

.cmb14 {
  margin-bottom: 112px; }

.cmx14 {
  margin-left: 112px;
  margin-right: 112px; }

.cmy14 {
  margin-top: 112px;
  margin-bottom: 112px; }

/* margin 120px */
.cm15 {
  margin: 120px; }

.cml15 {
  margin-left: 120px; }

.cmr15 {
  margin-right: 120px; }

.cmt15 {
  margin-top: 120px; }

.cmb15 {
  margin-bottom: 120px; }

.cmx15 {
  margin-left: 120px;
  margin-right: 120px; }

.cmy15 {
  margin-top: 120px;
  margin-bottom: 120px; }

/* margin 128px */
.cm16 {
  margin: 128px; }

.cml16 {
  margin-left: 128px; }

.cmr16 {
  margin-right: 128px; }

.cmt16 {
  margin-top: 128px; }

.cmb16 {
  margin-bottom: 128px; }

.cmx16 {
  margin-left: 128px;
  margin-right: 128px; }

.cmy16 {
  margin-top: 128px;
  margin-bottom: 128px; }

/* margin 136px */
.cm17 {
  margin: 136px; }

.cml17 {
  margin-left: 136px; }

.cmr17 {
  margin-right: 136px; }

.cmt17 {
  margin-top: 136px; }

.cmb17 {
  margin-bottom: 136px; }

.cmx17 {
  margin-left: 136px;
  margin-right: 136px; }

.cmy17 {
  margin-top: 136px;
  margin-bottom: 136px; }

/* margin 144px */
.cm18 {
  margin: 144px; }

.cml18 {
  margin-left: 144px; }

.cmr18 {
  margin-right: 144px; }

.cmt18 {
  margin-top: 144px; }

.cmb18 {
  margin-bottom: 144px; }

.cmx18 {
  margin-left: 144px;
  margin-right: 144px; }

.cmy18 {
  margin-top: 144px;
  margin-bottom: 144px; }

/* margin 152px */
.cm19 {
  margin: 152px; }

.cml19 {
  margin-left: 152px; }

.cmr19 {
  margin-right: 152px; }

.cmt19 {
  margin-top: 152px; }

.cmb19 {
  margin-bottom: 152px; }

.cmx19 {
  margin-left: 152px;
  margin-right: 152px; }

.cmy19 {
  margin-top: 152px;
  margin-bottom: 152px; }

/* margin 160px */
.cm20 {
  margin: 160px; }

.cml20 {
  margin-left: 160px; }

.cmr20 {
  margin-right: 160px; }

.cmt20 {
  margin-top: 160px; }

.cmb20 {
  margin-bottom: 160px; }

.cmx20 {
  margin-left: 160px;
  margin-right: 160px; }

.cmy20 {
  margin-top: 160px;
  margin-bottom: 160px; }

/* margin 168px */
.cm21 {
  margin: 168px; }

.cml21 {
  margin-left: 168px; }

.cmr21 {
  margin-right: 168px; }

.cmt21 {
  margin-top: 168px; }

.cmb21 {
  margin-bottom: 168px; }

.cmx21 {
  margin-left: 168px;
  margin-right: 168px; }

.cmy21 {
  margin-top: 168px;
  margin-bottom: 168px; }

/* margin 176px */
.cm22 {
  margin: 176px; }

.cml22 {
  margin-left: 176px; }

.cmr22 {
  margin-right: 176px; }

.cmt22 {
  margin-top: 176px; }

.cmb22 {
  margin-bottom: 176px; }

.cmx22 {
  margin-left: 176px;
  margin-right: 176px; }

.cmy22 {
  margin-top: 176px;
  margin-bottom: 176px; }

/* margin 184px */
.cm23 {
  margin: 184px; }

.cml23 {
  margin-left: 184px; }

.cmr23 {
  margin-right: 184px; }

.cmt23 {
  margin-top: 184px; }

.cmb23 {
  margin-bottom: 184px; }

.cmx23 {
  margin-left: 184px;
  margin-right: 184px; }

.cmy23 {
  margin-top: 184px;
  margin-bottom: 184px; }

/* margin 192px */
.cm24 {
  margin: 192px; }

.cml24 {
  margin-left: 192px; }

.cmr24 {
  margin-right: 192px; }

.cmt24 {
  margin-top: 192px; }

.cmb24 {
  margin-bottom: 192px; }

.cmx24 {
  margin-left: 192px;
  margin-right: 192px; }

.cmy24 {
  margin-top: 192px;
  margin-bottom: 192px; }

/* margin 200px */
.cm25 {
  margin: 200px; }

.cml25 {
  margin-left: 200px; }

.cmr25 {
  margin-right: 200px; }

.cmt25 {
  margin-top: 200px; }

.cmb25 {
  margin-bottom: 200px; }

.cmx25 {
  margin-left: 200px;
  margin-right: 200px; }

.cmy25 {
  margin-top: 200px;
  margin-bottom: 200px; }

/* margin 208px */
.cm26 {
  margin: 208px; }

.cml26 {
  margin-left: 208px; }

.cmr26 {
  margin-right: 208px; }

.cmt26 {
  margin-top: 208px; }

.cmb26 {
  margin-bottom: 208px; }

.cmx26 {
  margin-left: 208px;
  margin-right: 208px; }

.cmy26 {
  margin-top: 208px;
  margin-bottom: 208px; }

/* margin 216px */
.cm27 {
  margin: 216px; }

.cml27 {
  margin-left: 216px; }

.cmr27 {
  margin-right: 216px; }

.cmt27 {
  margin-top: 216px; }

.cmb27 {
  margin-bottom: 216px; }

.cmx27 {
  margin-left: 216px;
  margin-right: 216px; }

.cmy27 {
  margin-top: 216px;
  margin-bottom: 216px; }

/* margin 224px */
.cm28 {
  margin: 224px; }

.cml28 {
  margin-left: 224px; }

.cmr28 {
  margin-right: 224px; }

.cmt28 {
  margin-top: 224px; }

.cmb28 {
  margin-bottom: 224px; }

.cmx28 {
  margin-left: 224px;
  margin-right: 224px; }

.cmy28 {
  margin-top: 224px;
  margin-bottom: 224px; }

/* margin 232px */
.cm29 {
  margin: 232px; }

.cml29 {
  margin-left: 232px; }

.cmr29 {
  margin-right: 232px; }

.cmt29 {
  margin-top: 232px; }

.cmb29 {
  margin-bottom: 232px; }

.cmx29 {
  margin-left: 232px;
  margin-right: 232px; }

.cmy29 {
  margin-top: 232px;
  margin-bottom: 232px; }

/* margin 240px */
.cm30 {
  margin: 240px; }

.cml30 {
  margin-left: 240px; }

.cmr30 {
  margin-right: 240px; }

.cmt30 {
  margin-top: 240px; }

.cmb30 {
  margin-bottom: 240px; }

.cmx30 {
  margin-left: 240px;
  margin-right: 240px; }

.cmy30 {
  margin-top: 240px;
  margin-bottom: 240px; }

/* padding 8px */
.cp1 {
  padding: 8px; }

.cpl1 {
  padding-left: 8px; }

.cpr1 {
  padding-right: 8px; }

.cpt1 {
  padding-top: 8px; }

.cpb1 {
  padding-bottom: 8px; }

.cpx1 {
  padding-left: 8px;
  padding-right: 8px; }

.cpy1 {
  padding-top: 8px;
  padding-bottom: 8px; }

/* padding 16px */
.cp2 {
  padding: 16px; }

.cpl2 {
  padding-left: 16px; }

.cpr2 {
  padding-right: 16px; }

.cpt2 {
  padding-top: 16px; }

.cpb2 {
  padding-bottom: 16px; }

.cpx2 {
  padding-left: 16px;
  padding-right: 16px; }

.cpy2 {
  padding-top: 16px;
  padding-bottom: 16px; }

/* padding 24px */
.cp3 {
  padding: 24px; }

.cpl3 {
  padding-left: 24px; }

.cpr3 {
  padding-right: 24px; }

.cpt3 {
  padding-top: 24px; }

.cpb3 {
  padding-bottom: 24px; }

.cpx3 {
  padding-left: 24px;
  padding-right: 24px; }

.cpy3 {
  padding-top: 24px;
  padding-bottom: 24px; }

/* padding 32px */
.cp4 {
  padding: 32px; }

.cpl4 {
  padding-left: 32px; }

.cpr4 {
  padding-right: 32px; }

.cpt4 {
  padding-top: 32px; }

.cpb4 {
  padding-bottom: 32px; }

.cpx4 {
  padding-left: 32px;
  padding-right: 32px; }

.cpy4 {
  padding-top: 32px;
  padding-bottom: 32px; }

/* padding 40px */
.cp5 {
  padding: 40px; }

.cpl5 {
  padding-left: 40px; }

.cpr5 {
  padding-right: 40px; }

.cpt5 {
  padding-top: 40px; }

.cpb5 {
  padding-bottom: 40px; }

.cpx5 {
  padding-left: 40px;
  padding-right: 40px; }

.cpy5 {
  padding-top: 40px;
  padding-bottom: 40px; }

/* padding 48px */
.cp6 {
  padding: 48px; }

.cpl6 {
  padding-left: 48px; }

.cpr6 {
  padding-right: 48px; }

.cpt6 {
  padding-top: 48px; }

.cpb6 {
  padding-bottom: 48px; }

.cpx6 {
  padding-left: 48px;
  padding-right: 48px; }

.cpy6 {
  padding-top: 48px;
  padding-bottom: 48px; }

/* padding 56px */
.cp7 {
  padding: 56px; }

.cpl7 {
  padding-left: 56px; }

.cpr7 {
  padding-right: 56px; }

.cpt7 {
  padding-top: 56px; }

.cpb7 {
  padding-bottom: 56px; }

.cpx7 {
  padding-left: 56px;
  padding-right: 56px; }

.cpy7 {
  padding-top: 56px;
  padding-bottom: 56px; }

/* padding 64px */
.cp8 {
  padding: 64px; }

.cpl8 {
  padding-left: 64px; }

.cpr8 {
  padding-right: 64px; }

.cpt8 {
  padding-top: 64px; }

.cpb8 {
  padding-bottom: 64px; }

.cpx8 {
  padding-left: 64px;
  padding-right: 64px; }

.cpy8 {
  padding-top: 64px;
  padding-bottom: 64px; }

/* padding 72px */
.cp9 {
  padding: 72px; }

.cpl9 {
  padding-left: 72px; }

.cpr9 {
  padding-right: 72px; }

.cpt9 {
  padding-top: 72px; }

.cpb9 {
  padding-bottom: 72px; }

.cpx9 {
  padding-left: 72px;
  padding-right: 72px; }

.cpy9 {
  padding-top: 72px;
  padding-bottom: 72px; }

/* padding 80px */
.cp10 {
  padding: 80px; }

.cpl10 {
  padding-left: 80px; }

.cpr10 {
  padding-right: 80px; }

.cpt10 {
  padding-top: 80px; }

.cpb10 {
  padding-bottom: 80px; }

.cpx10 {
  padding-left: 80px;
  padding-right: 80px; }

.cpy10 {
  padding-top: 80px;
  padding-bottom: 80px; }

/* padding 88px */
.cp11 {
  padding: 88px; }

.cpl11 {
  padding-left: 88px; }

.cpr11 {
  padding-right: 88px; }

.cpt11 {
  padding-top: 88px; }

.cpb11 {
  padding-bottom: 88px; }

.cpx11 {
  padding-left: 88px;
  padding-right: 88px; }

.cpy11 {
  padding-top: 88px;
  padding-bottom: 88px; }

/* padding 96px */
.cp12 {
  padding: 96px; }

.cpl12 {
  padding-left: 96px; }

.cpr12 {
  padding-right: 96px; }

.cpt12 {
  padding-top: 96px; }

.cpb12 {
  padding-bottom: 96px; }

.cpx12 {
  padding-left: 96px;
  padding-right: 96px; }

.cpy12 {
  padding-top: 96px;
  padding-bottom: 96px; }

/* padding 104px */
.cp13 {
  padding: 104px; }

.cpl13 {
  padding-left: 104px; }

.cpr13 {
  padding-right: 104px; }

.cpt13 {
  padding-top: 104px; }

.cpb13 {
  padding-bottom: 104px; }

.cpx13 {
  padding-left: 104px;
  padding-right: 104px; }

.cpy13 {
  padding-top: 104px;
  padding-bottom: 104px; }

/* padding 112px */
.cp14 {
  padding: 112px; }

.cpl14 {
  padding-left: 112px; }

.cpr14 {
  padding-right: 112px; }

.cpt14 {
  padding-top: 112px; }

.cpb14 {
  padding-bottom: 112px; }

.cpx14 {
  padding-left: 112px;
  padding-right: 112px; }

.cpy14 {
  padding-top: 112px;
  padding-bottom: 112px; }

/* padding 120px */
.cp15 {
  padding: 120px; }

.cpl15 {
  padding-left: 120px; }

.cpr15 {
  padding-right: 120px; }

.cpt15 {
  padding-top: 120px; }

.cpb15 {
  padding-bottom: 120px; }

.cpx15 {
  padding-left: 120px;
  padding-right: 120px; }

.cpy15 {
  padding-top: 120px;
  padding-bottom: 120px; }

/* padding 128px */
.cp16 {
  padding: 128px; }

.cpl16 {
  padding-left: 128px; }

.cpr16 {
  padding-right: 128px; }

.cpt16 {
  padding-top: 128px; }

.cpb16 {
  padding-bottom: 128px; }

.cpx16 {
  padding-left: 128px;
  padding-right: 128px; }

.cpy16 {
  padding-top: 128px;
  padding-bottom: 128px; }

/* padding 136px */
.cp17 {
  padding: 136px; }

.cpl17 {
  padding-left: 136px; }

.cpr17 {
  padding-right: 136px; }

.cpt17 {
  padding-top: 136px; }

.cpb17 {
  padding-bottom: 136px; }

.cpx17 {
  padding-left: 136px;
  padding-right: 136px; }

.cpy17 {
  padding-top: 136px;
  padding-bottom: 136px; }

/* padding 144px */
.cp18 {
  padding: 144px; }

.cpl18 {
  padding-left: 144px; }

.cpr18 {
  padding-right: 144px; }

.cpt18 {
  padding-top: 144px; }

.cpb18 {
  padding-bottom: 144px; }

.cpx18 {
  padding-left: 144px;
  padding-right: 144px; }

.cpy18 {
  padding-top: 144px;
  padding-bottom: 144px; }

/* padding 152px */
.cp19 {
  padding: 152px; }

.cpl19 {
  padding-left: 152px; }

.cpr19 {
  padding-right: 152px; }

.cpt19 {
  padding-top: 152px; }

.cpb19 {
  padding-bottom: 152px; }

.cpx19 {
  padding-left: 152px;
  padding-right: 152px; }

.cpy19 {
  padding-top: 152px;
  padding-bottom: 152px; }

/* padding 160px */
.cp20 {
  padding: 160px; }

.cpl20 {
  padding-left: 160px; }

.cpr20 {
  padding-right: 160px; }

.cpt20 {
  padding-top: 160px; }

.cpb20 {
  padding-bottom: 160px; }

.cpx20 {
  padding-left: 160px;
  padding-right: 160px; }

.cpy20 {
  padding-top: 160px;
  padding-bottom: 160px; }

/* padding 168px */
.cp21 {
  padding: 168px; }

.cpl21 {
  padding-left: 168px; }

.cpr21 {
  padding-right: 168px; }

.cpt21 {
  padding-top: 168px; }

.cpb21 {
  padding-bottom: 168px; }

.cpx21 {
  padding-left: 168px;
  padding-right: 168px; }

.cpy21 {
  padding-top: 168px;
  padding-bottom: 168px; }

/* padding 176px */
.cp22 {
  padding: 176px; }

.cpl22 {
  padding-left: 176px; }

.cpr22 {
  padding-right: 176px; }

.cpt22 {
  padding-top: 176px; }

.cpb22 {
  padding-bottom: 176px; }

.cpx22 {
  padding-left: 176px;
  padding-right: 176px; }

.cpy22 {
  padding-top: 176px;
  padding-bottom: 176px; }

/* padding 184px */
.cp23 {
  padding: 184px; }

.cpl23 {
  padding-left: 184px; }

.cpr23 {
  padding-right: 184px; }

.cpt23 {
  padding-top: 184px; }

.cpb23 {
  padding-bottom: 184px; }

.cpx23 {
  padding-left: 184px;
  padding-right: 184px; }

.cpy23 {
  padding-top: 184px;
  padding-bottom: 184px; }

/* padding 192px */
.cp24 {
  padding: 192px; }

.cpl24 {
  padding-left: 192px; }

.cpr24 {
  padding-right: 192px; }

.cpt24 {
  padding-top: 192px; }

.cpb24 {
  padding-bottom: 192px; }

.cpx24 {
  padding-left: 192px;
  padding-right: 192px; }

.cpy24 {
  padding-top: 192px;
  padding-bottom: 192px; }

/* padding 200px */
.cp25 {
  padding: 200px; }

.cpl25 {
  padding-left: 200px; }

.cpr25 {
  padding-right: 200px; }

.cpt25 {
  padding-top: 200px; }

.cpb25 {
  padding-bottom: 200px; }

.cpx25 {
  padding-left: 200px;
  padding-right: 200px; }

.cpy25 {
  padding-top: 200px;
  padding-bottom: 200px; }

/* padding 208px */
.cp26 {
  padding: 208px; }

.cpl26 {
  padding-left: 208px; }

.cpr26 {
  padding-right: 208px; }

.cpt26 {
  padding-top: 208px; }

.cpb26 {
  padding-bottom: 208px; }

.cpx26 {
  padding-left: 208px;
  padding-right: 208px; }

.cpy26 {
  padding-top: 208px;
  padding-bottom: 208px; }

/* padding 216px */
.cp27 {
  padding: 216px; }

.cpl27 {
  padding-left: 216px; }

.cpr27 {
  padding-right: 216px; }

.cpt27 {
  padding-top: 216px; }

.cpb27 {
  padding-bottom: 216px; }

.cpx27 {
  padding-left: 216px;
  padding-right: 216px; }

.cpy27 {
  padding-top: 216px;
  padding-bottom: 216px; }

/* padding 224px */
.cp28 {
  padding: 224px; }

.cpl28 {
  padding-left: 224px; }

.cpr28 {
  padding-right: 224px; }

.cpt28 {
  padding-top: 224px; }

.cpb28 {
  padding-bottom: 224px; }

.cpx28 {
  padding-left: 224px;
  padding-right: 224px; }

.cpy28 {
  padding-top: 224px;
  padding-bottom: 224px; }

/* padding 232px */
.cp29 {
  padding: 232px; }

.cpl29 {
  padding-left: 232px; }

.cpr29 {
  padding-right: 232px; }

.cpt29 {
  padding-top: 232px; }

.cpb29 {
  padding-bottom: 232px; }

.cpx29 {
  padding-left: 232px;
  padding-right: 232px; }

.cpy29 {
  padding-top: 232px;
  padding-bottom: 232px; }

/* padding 240px */
.cp30 {
  padding: 240px; }

.cpl30 {
  padding-left: 240px; }

.cpr30 {
  padding-right: 240px; }

.cpt30 {
  padding-top: 240px; }

.cpb30 {
  padding-bottom: 240px; }

.cpx30 {
  padding-left: 240px;
  padding-right: 240px; }

.cpy30 {
  padding-top: 240px;
  padding-bottom: 240px; }

@media (max-width: 839px) {
  .cpx5 {
    padding-left: 16px;
    padding-right: 16px; } }

.svg_icon_pk path,
.svg_icon_pk circle {
  stroke: #2E2E2F; }

.spec-fill_pk path,
.spec-fill_pk circle {
  fill: #2E2E2F; }

.spec-fill_pk .none-fill {
  fill: none !important; }

.icon-button-table:hover path,
.icon-button-table:hover circle {
  stroke: var(--primary_hover); }

.side_svg_icon_pk path,
.side_svg_icon_pk circle {
  stroke: var(--primary_sidenav); }

.active_side_step .side_svg_icon_pk path,
.active_side_step .side_svg_icon_pk circle {
  stroke: var(--secondary_sidenav); }

.avatar_svg_icon_pk .background_svg {
  fill: var(--primary); }

.svg_icon_pk_fill_hover path,
.svg_icon_pk_fill_hover circle {
  fill: var(--primary_hover); }

.svg_icon_pk_fill path,
.svg_icon_pk_fill circle {
  fill: var(--primary); }

.tooth_img:hover .svg_icon_pk_fill path,
.tooth_img:hover .svg_icon_pk_fill circle {
  fill: var(--primary_hover); }

.tooth_img:hover .svg_icon_pk_fill_not_active path,
.tooth_img:hover .svg_icon_pk_fill_not_active circle {
  fill: var(--primary_hover); }

@media (max-width: 839px) {
  .tooth_img:hover .svg_icon_pk_fill_not_active path,
  .tooth_img:hover .svg_icon_pk_fill_not_active circle {
    fill: #898a8d; } }

.svg_icon_pk_fill_not_active {
  cursor: pointer; }
  .svg_icon_pk_fill_not_active path,
  .svg_icon_pk_fill_not_active circle {
    fill: #898a8d; }

.restrected .svg_icon_pk_fill_not_active path,
.restrected .svg_icon_pk_fill_not_active circle {
  fill: lightgray; }

.restrected .tooth_num {
  background-color: #898a8d !important;
  color: #fff; }
  .restrected .tooth_num:hover {
    border-color: 1px solid #898a8d !important;
    color: #fff !important;
    background-color: #898a8d !important; }

.restrected_tooth .svg_icon_pk_fill_not_active path,
.restrected_tooth .svg_icon_pk_fill_not_active circle {
  fill: #E4E4E4; }

.restrected_tooth .svg_icon_pk_fill_not_active .restrict-icon {
  visibility: visible; }

.tooth_image_active .svg_icon_pk_fill_not_active path,
.tooth_image_active .svg_icon_pk_fill_not_active circle {
  fill: var(--primary); }

.tooth_image_active:hover .svg_icon_pk_fill_not_active path,
.tooth_image_active:hover .svg_icon_pk_fill_not_active circle {
  fill: var(--primary_hover); }

@media (max-width: 839px) {
  .tooth_image_active:hover .svg_icon_pk_fill_not_active path,
  .tooth_image_active:hover .svg_icon_pk_fill_not_active circle {
    fill: var(--primary); } }

.form-check-input-pk:checked {
  background-color: var(--primary);
  border-color: var(--primary); }

.form-check-input-pk:focus {
  border-color: var(--primary_hover); }

.radio-item-pk {
  display: inline-block;
  position: relative; }

.radio-item-pk input[type="radio"] {
  display: none; }

.radio-item-pk label {
  color: #2e2e2f;
  font-weight: normal;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
  grid-gap: 4px;
  line-height: 20px; }
  .radio-item-pk label:disabled {
    cursor: unset; }
  @media (max-width: 839px) {
    .radio-item-pk label {
      font-size: 12px; } }

.radio-item-pk label:before {
  content: " ";
  cursor: pointer;
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 3px solid #d5d8df;
  background-color: #fff; }
  .radio-item-pk label:before:disabled {
    cursor: unset; }

.radio-item-pk input[type="radio"]:checked + label:before {
  border: 3px solid var(--primary); }

.radio-item-pk input[type="radio"]:checked + label:after {
  cursor: pointer;
  border-radius: 11px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 19px;
  left: 14px;
  content: " ";
  display: block;
  background: var(--primary); }
  .radio-item-pk input[type="radio"]:checked + label:after:disabled {
    cursor: unset; }

.radio-item-pk input[type="radio"]:checked:hover + label:after {
  border-radius: 11px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 19px;
  left: 14px;
  content: " ";
  display: block;
  background: var(--primary_hover); }
  .radio-item-pk input[type="radio"]:checked:hover + label:after:disabled {
    cursor: unset; }

.custom-radio-group-grid-v-3-pk {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
  font-size: 12px; }
  @media (max-width: 839px) {
    .custom-radio-group-grid-v-3-pk {
      grid-template-columns: 1fr; } }

.custom-radio-group-grid-v-pk {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 12px; }
  @media (max-width: 839px) {
    .custom-radio-group-grid-v-pk {
      grid-template-columns: 1fr; } }

.group-grid-v-pk {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 8px; }
  @media (max-width: 839px) {
    .group-grid-v-pk {
      grid-template-columns: 1fr; } }

.custom-radio-group-grid-fullGrid-pk {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px; }
  @media (max-width: 839px) {
    .custom-radio-group-grid-fullGrid-pk {
      grid-template-columns: 1fr; } }

.custom-radio-group-grid-fourGrid-pk {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 12px; }
  @media (max-width: 839px) {
    .custom-radio-group-grid-fourGrid-pk {
      grid-template-columns: 1fr; } }

.custom-radio-group-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 8px; }

.radio-label-pk {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px; }

.fourGridLabel {
  display: grid;
  grid-template-columns: auto 1fr; }

.radio-item-malc-pk {
  display: inline-block;
  position: relative; }

.radio-item-malc-pk input[type="radio"] {
  display: none; }

.radio-item-malc-pk label {
  color: #2e2e2f;
  font-weight: normal;
  cursor: pointer;
  display: unset !important;
  align-items: baseline;
  grid-gap: 4px;
  line-height: 20px; }
  .radio-item-malc-pk label:disabled {
    cursor: unset; }
  @media (max-width: 839px) {
    .radio-item-malc-pk label {
      font-size: 12px; } }

.radio-item-malc-pk label:before {
  content: " ";
  cursor: pointer;
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 3px solid #d5d8df;
  background-color: #fff; }
  .radio-item-malc-pk label:before:disabled {
    cursor: unset; }

.radio-item-malc-pk input[type="radio"]:checked + label:before {
  border: 3px solid var(--primary); }

.radio-item-malc-pk input[type="radio"]:checked + label:after {
  cursor: pointer;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  top: calc(50% - 2px);
  left: 10px;
  content: " ";
  display: block;
  background: var(--primary); }
  .radio-item-malc-pk input[type="radio"]:checked + label:after:disabled {
    cursor: unset; }

.radio-item-malc-pk input[type="radio"]:checked:hover + label:after {
  background: var(--primary_hover); }
  .radio-item-malc-pk input[type="radio"]:checked:hover + label:after:disabled {
    cursor: unset; }

.radio-item-malc-pk input[type="radio"]:checked,
.radio-item-malc-pk input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px; }

.radio-item-malc-pk input[type="radio"]:checked + label,
.radio-item-malc-pk input[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  font-weight: normal; }

.radio-item-malc-pk input[type="radio"]:checked + label:before,
.radio-item-malc-pk input[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff; }

.radio-item-malc-pk input[type="radio"]:checked + label:after,
.radio-item-malc-pk input[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--primary);
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease; }

.radio-item-malc-pk input[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0); }

.radio-item-malc-pk input[type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1); }

.pointerUnset {
  cursor: unset !important; }
  .pointerUnset radio-item-malc-pk label:before {
    cursor: unset !important; }

.pointerUnset {
  cursor: unset !important; }
  .pointerUnset radio-item-pk label:before {
    cursor: unset !important; }

.advanced-modules-modal .modal-body {
  padding-top: 2px; }

.advanced-modules-modal .edit-container .inner_container_pk {
  display: grid;
  grid-template-columns: 1fr auto; }

.advanced-modules-modal .edit-container .module-options-title,
.advanced-modules-modal .edit-container .modules_titles_pk {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2e2e2f; }
  @media (max-width: 839px) {
    .advanced-modules-modal .edit-container .module-options-title,
    .advanced-modules-modal .edit-container .modules_titles_pk {
      font-size: 12px; } }

.advanced-modules-modal .edit-container .module-reset,
.advanced-modules-modal .edit-container .reset_default_settings_PK {
  font-weight: 400;
  font-size: 13.756px;
  cursor: pointer;
  line-height: 22px;
  letter-spacing: 0.006em;
  text-decoration-line: underline;
  color: #2e2e2f; }
  @media (max-width: 839px) {
    .advanced-modules-modal .edit-container .module-reset,
    .advanced-modules-modal .edit-container .reset_default_settings_PK {
      font-size: 10px; } }

.advanced-modules-modal .edit-container .checkbox-options label {
  font-size: 14px;
  line-height: 21px;
  color: #2e2e2f; }
  .advanced-modules-modal .edit-container .checkbox-options label.disabled {
    color: #c8c8ca; }

.advanced-modules-modal .actions-container,
.button_container_PK {
  margin: 0 16px;
  display: flex;
  border-top: 1px solid #e4e4e4;
  padding-top: 10px;
  gap: 20px; }
  .advanced-modules-modal .actions-container > div:first-child,
  .button_container_PK > div:first-child {
    flex: 1 1 auto; }

.Discard-button-pk {
  margin: auto;
  text-align: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding-top: 3px;
  color: #898a8d; }
  @media (max-width: 839px) {
    .Discard-button-pk {
      font-size: 12px; } }

.teeth-container-pk {
  display: grid;
  grid-template-columns: 20px auto 20px;
  place-items: center; }
  @media (max-width: 839px) {
    .teeth-container-pk {
      width: 100%;
      grid-template-columns: 1px auto 1px; } }

.teeth-container-pk-test {
  width: 90%; }
  @media (max-width: 839px) {
    .teeth-container-pk-test {
      width: 100%; } }

.teeth-container-v3 {
  display: grid;
  grid-template-columns: auto 10px; }
  @media (max-width: 839px) {
    .teeth-container-v3 {
      width: 100%;
      grid-template-columns: auto; } }

.advanced-module-button {
  cursor: pointer;
  background: #fff;
  color: #2e2e2f;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  height: 100%;
  border: 2px solid #fff; }
  .advanced-module-button:active {
    border: 2px solid var(--primary); }
  .advanced-module-button.active {
    background: #fff;
    color: var(--primary);
    border: 2px solid var(--primary);
    box-shadow: 0px 8px 12px hexa(var(--primary_hover), 0.3);
    min-height: 72px; }
    .advanced-module-button.active .svg_icon_pk path,
    .advanced-module-button.active .svg_icon_pk circle {
      stroke: var(--primary); }
    .advanced-module-button.active .spec-fill_pk path,
    .advanced-module-button.active .spec-fill_pk circle {
      fill: var(--primary); }
  @media (max-width: 839px) {
    .advanced-module-button {
      padding: 16px 8px;
      width: 100%;
      max-width: 100%; } }
  .advanced-module-button .text {
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px; }
    @media (max-width: 839px) {
      .advanced-module-button .text {
        line-height: 22px; } }
    @media (max-width: 375px) {
      .advanced-module-button .text {
        font-size: 10px !important; } }

li.radio-real-button {
  list-style-type: none; }
  li.radio-real-button input[type="radio"] {
    display: none; }
  li.radio-real-button input[type="radio"] + label {
    background: #f2f4f5; }
  li.radio-real-button input[type="radio"]:checked + label,
  li.radio-real-button .Checked + label {
    color: #3084f0;
    background: rgba(48, 132, 240, 0.2); }
  li.radio-real-button label {
    cursor: pointer;
    z-index: 90; }
  li.radio-real-button label:hover {
    background: rgba(90, 140, 206, 0.4); }

.advanced-modules-modal-wrap *,
.advanced-modules-modal-wrap ::before,
.advanced-modules-modal-wrap ::after {
  box-sizing: border-box; }

.advanced-modules-modal-wrap button,
.advanced-modules-modal-wrap input,
.advanced-modules-modal-wrap optgroup,
.advanced-modules-modal-wrap select,
.advanced-modules-modal-wrap textarea {
  font-family: inherit;
  font-size: 14px;
  padding: 8px;
  line-height: inherit; }

.advanced-modules-modal-wrap .advanced-modules-modal {
  width: 55%;
  height: -moz-fit-content;
  height: fit-content;
  top: 0vh;
  max-width: 900px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 12px; }
  @media (max-width: 1070px) {
    .advanced-modules-modal-wrap .advanced-modules-modal {
      width: 75%;
      max-width: 75%; } }
  @media (max-width: 930px) {
    .advanced-modules-modal-wrap .advanced-modules-modal {
      width: 85%;
      max-width: 85%; } }
  @media (max-width: 839px) {
    .advanced-modules-modal-wrap .advanced-modules-modal {
      width: 95% !important;
      max-width: 95% !important; } }

.advanced-modules-modal-wrap .fade {
  transition: opacity 0.15s linear; }

@media (prefers-reduced-motion: reduce) {
  .advanced-modules-modal-wrap .fade {
    transition: none; } }

.advanced-modules-modal-wrap .fade:not(.show) {
  opacity: 0; }

.advanced-modules-modal-wrap .modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 16px;
  --bs-modal-margin: 8px;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 8px;
  --bs-modal-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(8px - 1px);
  --bs-modal-header-padding-x: 16px;
  --bs-modal-header-padding-y: 16px;
  --bs-modal-header-padding: 16px 16px;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 8px;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto !important;
  outline: 0; }

.advanced-modules-modal-wrap .modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none; }

.advanced-modules-modal-wrap .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px); }

@media (prefers-reduced-motion: reduce) {
  .advanced-modules-modal-wrap .modal.fade .modal-dialog {
    transition: none; } }

.advanced-modules-modal-wrap .modal.show .modal-dialog {
  transform: none; }

.advanced-modules-modal-wrap .modal.modal-static .modal-dialog {
  transform: scale(1.02); }

.advanced-modules-modal-wrap .modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }

.advanced-modules-modal-wrap .modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden; }

.advanced-modules-modal-wrap .modal-dialog-scrollable .modal-body {
  overflow-y: auto; }

.advanced-modules-modal-wrap .modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2); }

.advanced-modules-modal-wrap .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0; }

.advanced-modules-modal-wrap .modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg); }

.advanced-modules-modal-wrap .modal-backdrop.fade {
  opacity: 0; }

.advanced-modules-modal-wrap .modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity); }

.advanced-modules-modal-wrap .modal-header {
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: none !important;
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius); }

.advanced-modules-modal-wrap .modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto; }

.advanced-modules-modal-wrap .modal-title {
  margin-bottom: 0;
  margin-top: 0;
  width: 100%;
  line-height: var(--bs-modal-title-line-height); }

.advanced-modules-modal-wrap .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding); }

.advanced-modules-modal-wrap .modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius); }

.advanced-modules-modal-wrap .modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5); }

@media (min-width: 576px) {
  .advanced-modules-modal-wrap .modal {
    --bs-modal-margin: 28px;
    --bs-modal-box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); }
  .advanced-modules-modal-wrap .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto; }
  .advanced-modules-modal-wrap .modal-sm {
    --bs-modal-width: 300px; } }

@media (min-width: 992px) {
  .advanced-modules-modal-wrap .modal-lg,
  .advanced-modules-modal-wrap .modal-xl {
    --bs-modal-width: 800px; } }

@media (min-width: 1200px) {
  .advanced-modules-modal-wrap .modal-xl {
    --bs-modal-width: 1140px; } }

.advanced-modules-modal-wrap .modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }

.advanced-modules-modal-wrap .modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0; }

.advanced-modules-modal-wrap .modal-fullscreen .modal-header,
.advanced-modules-modal-wrap .modal-fullscreen .modal-footer {
  border-radius: 0; }

.advanced-modules-modal-wrap .modal-fullscreen .modal-body {
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .advanced-modules-modal-wrap .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-sm-down .modal-header,
  .advanced-modules-modal-wrap .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto; } }

@media (max-width: 767.98px) {
  .advanced-modules-modal-wrap .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-md-down .modal-header,
  .advanced-modules-modal-wrap .modal-fullscreen-md-down .modal-footer {
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-md-down .modal-body {
    overflow-y: auto; } }

@media (max-width: 991.98px) {
  .advanced-modules-modal-wrap .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-lg-down .modal-header,
  .advanced-modules-modal-wrap .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .advanced-modules-modal-wrap .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-xl-down .modal-header,
  .advanced-modules-modal-wrap .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto; } }

@media (max-width: 1399.98px) {
  .advanced-modules-modal-wrap .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-xxl-down .modal-header,
  .advanced-modules-modal-wrap .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0; }
  .advanced-modules-modal-wrap .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto; } }

.customizer {
  background: #fff;
  position: fixed;
  height: 100%;
  width: 280px;
  top: 0px;
  right: -280px;
  z-index: 100;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  transition: 0.3s ease-in; }

.customizer .translation_toggle {
  background: #1c64f2 !important;
  top: 40%;
  max-width: 54px; }

.customizer .service-panel-toggle {
  background: #fc4b6c;
  padding: 12px 20px;
  color: #fff;
  position: absolute;
  top: 30%;
  left: -57px;
  cursor: pointer; }

.svg_icon path,
.svg_icon circle {
  stroke: var(--primary); }

.svg_email_icon path,
.svg_email_icon circle {
  fill: var(--primary); }

.icon-button-table:hover path,
.icon-button-table:hover circle {
  stroke: var(--primary_hover); }

.side_svg_icon path,
.side_svg_icon circle {
  stroke: var(--primary_sidenav); }

.active_side_step .side_svg_icon path,
.active_side_step .side_svg_icon circle {
  stroke: var(--secondary_sidenav); }

.avatar_svg_icon .background_svg {
  fill: var(--primary); }

.svg_icon_fill_hover path,
.svg_icon_fill_hover circle {
  fill: var(--primary_hover); }

.svg_icon_fill path,
.svg_icon_fill circle {
  fill: var(--primary); }

.tooth_img:hover .svg_icon_fill path,
.tooth_img:hover .svg_icon_fill circle {
  fill: var(--primary_hover); }

.tooth_img:hover .svg_icon_fill_not_active path,
.tooth_img:hover .svg_icon_fill_not_active circle {
  fill: var(--primary_hover); }

.svg_icon_fill_not_active {
  cursor: pointer; }
  .svg_icon_fill_not_active path,
  .svg_icon_fill_not_active circle {
    fill: #898a8d; }

.tooth_image_active .svg_icon_fill_not_active path,
.tooth_image_active .svg_icon_fill_not_active circle {
  fill: var(--primary); }

.tooth_image_active:hover .svg_icon_fill_not_active path,
.tooth_image_active:hover .svg_icon_fill_not_active circle {
  fill: var(--primary_hover); }

.translate_icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  -moz-user-select: none; }

.svg_icon_traslation_secondary {
  color: white;
  font-size: 18px !important; }
  .svg_icon_traslation_secondary path {
    fill: white; }

/*******************
customizer
*******************/
.customizer {
  background: #fff;
  position: fixed;
  height: 100%;
  width: 280px;
  top: 0px;
  right: -280px;
  z-index: 100;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  transition: 0.3s ease-in; }
  .customizer .service-panel-toggle {
    background: #fc4b6c;
    padding: 12px 20px;
    color: #fff;
    position: absolute;
    top: 30%;
    left: -57px;
    cursor: pointer; }
  .customizer .translation_toggle {
    background: #1c64f2 !important;
    top: 40%;
    max-width: 54px;
    padding: auto; }
  .customizer.show-service-panel {
    right: 0px; }
  .customizer .customizer-body {
    position: relative;
    height: 100%; }
  .customizer .customizer-tab {
    display: flex; }
    .customizer .customizer-tab .nav-item {
      width: 33.33%;
      text-align: center; }
      .customizer .customizer-tab .nav-item .nav-link {
        padding: 15px 12px;
        color: black;
        border-bottom: 3px solid transparent; }

.customizer {
  right: -280px;
  left: auto; }
  .customizer.show-service-panel {
    right: 0;
    left: auto; }
  .customizer .service-panel-toggle {
    left: -54px;
    right: auto; }

.edit_icon_translation {
  position: absolute;
  right: 10px;
  z-index: 20;
  top: 2%;
  cursor: pointer; }
  .edit_icon_translation svg {
    height: 20px;
    width: 20px; }

.translation_container {
  position: relative; }

.edit_translation {
  pointer-events: unset;
  cursor: pointer;
  height: 16px;
  width: 16px; }

.svg_icon_traslation {
  color: var(--primary);
  font-size: 24px !important; }

.svg_icon_traslation_secondary2 {
  color: var(--secondary);
  font-size: 24px !important; }

.svg_icon_traslation_secondary {
  color: var(--secondary);
  font-size: 18px !important; }

.missing_translation {
  color: #f44336 !important; }
  .missing_translation textarea {
    color: #f44336 !important; }

.case_stepper .edit_icon_translation {
  top: 10%; }

.submission_container .edit_icon_translation {
  top: 11%; }

.patient-info-container .edit_icon_translation {
  top: 20%; }

.case_details_container .edit_icon_translation {
  top: 15%; }

.patient_info_actions_grid {
  position: relative; }
  .patient_info_actions_grid .edit_icon_translation {
    top: 5%; }

.retainer_info_container {
  position: relative; }
  .retainer_info_container .edit_icon_translation {
    top: -20%; }

.stages_container {
  position: relative; }
  .stages_container .edit_icon_translation {
    top: 10%; }

.translation_other_components_container {
  position: relative; }
  .translation_other_components_container .edit_icon_translation {
    top: 0; }

.translation_other_components_container_ts {
  position: relative; }
  .translation_other_components_container_ts .edit_icon_translation {
    top: 5px; }

.patient_case_grid {
  position: relative; }
  .patient_case_grid .edit_icon_translation {
    top: 0; }

.App {
  border: 2px solid #d3d3d3;
  border-radius: 0.5em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  padding: 1em;
  text-align: left;
  width: 600px; }

.form {
  padding: 1em; }

label {
  font-weight: 600; }

.translation_modal .modal-content {
  max-height: 92vh; }

.translation_modal .tab-content {
  max-height: 60vh;
  overflow: auto; }

.advanced-modules-container *,
.advanced-modules-container ::before,
.advanced-modules-container ::after,
.advanced-modules-summary *,
.advanced-modules-summary ::before,
.advanced-modules-summary ::after,
.advanced-modules-modal-wrap *,
.advanced-modules-modal-wrap ::before,
.advanced-modules-modal-wrap ::after {
  box-sizing: border-box;
  font-family: Poppins !important; }

.advanced-modules-container svg,
.advanced-modules-summary svg,
.advanced-modules-modal-wrap svg {
  display: inline-block; }

.advanced-modules-container input[type="checkbox"],
.advanced-modules-summary input[type="checkbox"],
.advanced-modules-modal-wrap input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  outline-color: #898a8d;
  position: relative !important;
  background-color: white;
  padding: 8px !important;
  margin: 0;
  outline-width: 1px;
  outline-style: solid;
  border-radius: 5px; }

.advanced-modules-container input[type="checkbox"]:checked,
.advanced-modules-summary input[type="checkbox"]:checked,
.advanced-modules-modal-wrap input[type="checkbox"]:checked {
  background-color: var(--primary);
  content: url("data:image/svg+xml,%3Csvg width%3D%2215%22 height%3D%2212%22 viewBox%3D%220 0 15 12%22 fill%3D%22none%22 xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0D    %3Cpath d%3D%22M1 6L5.34075 11L14 1%22 stroke%3D%22white%22 stroke-width%3D%221.5%22 stroke-linecap%3D%22round%22 stroke-linejoin%3D%22round%22 %2F%3E%0D%3C%2Fsvg%3E%0D    ") !important;
  padding: 2px 0.5px !important;
  outline-style: none; }

.advanced-modules-container input[type="checkbox"]:disabled,
.advanced-modules-summary input[type="checkbox"]:disabled,
.advanced-modules-modal-wrap input[type="checkbox"]:disabled {
  outline-color: #3d1717;
  background-color: #efeff0; }

.advanced-modules-container input[type="checkbox"]:checked:disabled,
.advanced-modules-summary input[type="checkbox"]:checked:disabled,
.advanced-modules-modal-wrap input[type="checkbox"]:checked:disabled {
  outline-color: #3d1717;
  background-color: var(--primary); }

.advanced-modules-container input[type="checkbox"]:disabled + label,
.advanced-modules-summary input[type="checkbox"]:disabled + label,
.advanced-modules-modal-wrap input[type="checkbox"]:disabled + label {
  color: gray; }

.advanced-modules-container input[type="checkbox"]:checked:disabled + label,
.advanced-modules-summary input[type="checkbox"]:checked:disabled + label,
.advanced-modules-modal-wrap input[type="checkbox"]:checked:disabled + label {
  color: #2e2e2f; }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 160px;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 8px;
  --bs-dropdown-spacer: 2px;
  --bs-dropdown-font-size: 14px;
  --bs-dropdown-color: #67757c;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: #e9ecef;
  --bs-dropdown-border-radius: 2px;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: 1px;
  --bs-dropdown-divider-bg: #f6f9fc;
  --bs-dropdown-divider-margin-y: 8px;
  --bs-dropdown-box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
  --bs-dropdown-link-color: #455a64;
  --bs-dropdown-link-hover-color: #3e515a;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #1e88e5;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 16px;
  --bs-dropdown-item-padding-y: 10.4px;
  --bs-dropdown-header-color: #8898aa;
  --bs-dropdown-header-padding-x: 16px;
  --bs-dropdown-header-padding-y: 8px;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  box-shadow: var(--bs-dropdown-box-shadow); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1600px) {
  .dropdown-menu-xlg-start {
    --bs-position: start; }
    .dropdown-menu-xlg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xlg-end {
    --bs-position: end; }
    .dropdown-menu-xlg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer); }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer); }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer); }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 12.25008px;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap; }
  @media (min-width: 1200px) {
    .dropdown-header {
      font-size: 12.24992px; } }

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color); }

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #263238;
  --bs-dropdown-border-color: #e9ecef;
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: #f6f9fc;
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #1e88e5;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd; }

.advanced-modules-container,
.advanced-modules-summary,
.advanced-modules-modal-wrap {
  --secondary_text_pk: #2e2e2f; }

.checked\:tw-bg-\[\#3084F0\]:checked{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(48 132 240 / var(--tw-bg-opacity)) !important; }

.hover\:tw-border-inherit:hover{
  border-color: inherit !important; }

@media (min-width: 640px){
  .sm\:tw-grid{
    display: grid !important; }
  .sm\:tw-grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important; }
  .sm\:tw-flex-row{
    flex-direction: row !important; }
  .sm\:tw-pb-10{
    padding-bottom: 40px !important; }
  .sm\:tw-text-xs{
    font-size: 12px !important;
    line-height: 16px !important; } }

@media (min-width: 768px){
  .md\:tw-right-\[5px\]{
    right: 5px !important; }
  .md\:tw-right-\[-8px\]{
    right: -8px !important; }
  .md\:tw-grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important; }
  .md\:tw-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important; }
  .md\:tw-p-\[5px\]{
    padding: 5px !important; } }

@media (min-width: 1024px){
  .lg\:tw-flex{
    display: flex !important; }
  .lg\:tw-grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important; }
  .lg\:tw-text-sm{
    font-size: 14px !important;
    line-height: 20px !important; } }

.\[\&\>\*\]\:tw-flex>*{
  display: flex !important; }

.\[\&\>\*\]\:tw-h-\[18px\]>*{
  height: 18px !important; }

.\[\&\>\*\]\:tw-items-center>*{
  align-items: center !important; }

.\[\&\>\*\]\:tw-p-3>*{
  padding: 12px !important; }

.\[\&_path\]\:tw-fill-\[\#3084F0\] path{
  fill: #3084F0 !important; }

.\[\&_\.numbering\]\:tw-text-\[16px\] .numbering{
  font-size: 16px !important; }

.\[\&_\.numbering\]\:tw-leading-\[20px\] .numbering{
  line-height: 20px !important; }

.\[\&_\.arrow-num\]\:tw-text-\[12px\] .arrow-num{
  font-size: 12px !important; }

.\[\&_\.arrow-num\]\:tw-leading-\[7px\] .arrow-num{
  line-height: 7px !important; }

.\[\&_\.tooth-side\]\:tw-text-\[13px\] .tooth-side{
  font-size: 13px !important; }

.\[\&_\.tooth-side\.left\]\:tw-pl-\[6px\] .tooth-side.left{
  padding-left: 6px !important; }

.\[\&_\.tooth-side\.left\]\:tw-pr-\[0\] .tooth-side.left{
  padding-right: 0 !important; }

.\[\&_\.tooth-side\.right\]\:tw-pr-\[6px\] .tooth-side.right{
  padding-right: 6px !important; }

.\[\&_\.tooth-side\.right\]\:tw-pl-\[0px\] .tooth-side.right{
  padding-left: 0px !important; }

.\[\&_\#teeth-grid\.pdf_\.numbering\]\:tw-my-\[1px\] #teeth-grid.pdf .numbering{
  margin-top: 1px !important;
  margin-bottom: 1px !important; }

.\[\&_\*\:before\]\:tw-box-border *:before{
  box-sizing: border-box !important; }
